import React, { Component } from 'react';
import { isEmpty } from 'loadsh'
import { Call, Email, Edit, Pudo } from '../HelpSection/icons';
import { ChangeDeliveryIcon } from '../PudoStore/Icons';

class OrderDesktopQuickAction extends Component {


    getProcessFormList = (fontSize, fontFamily) => {
        if (!isEmpty(this.props.trackingDetails) && this.props.trackingDetails.processUpdateForm) {
            let forms = JSON.parse(this.props.trackingDetails.processUpdateForm);
            let arrayList = [];
            if (forms && forms.formList && !isEmpty(forms.formList)) {
                (forms.formList).map((form, index) => {
                    if (form.introText)
                        arrayList.push(
                            <li style={{ display: 'flex', padding: "10px" }} className='ci-order-quick-action' button key={index} onClick={this.props.openProcessForm(index)}>
                                <Edit />
                                {form.introText.includes(' ') ?
                                    <p style={{ marginLeft: "16px" }}>{form.introText.length > 32 ? <>{form.introText.substring(0, 32)}&hellip;</> : form.introText}</p>
                                    :
                                    <p style={{ marginLeft: "16px" }}>{form.introText.length > 13 ? <>{form.introText.substring(0, 13)}&hellip;</> : form.introText}</p>
                                }
                            </li>
                        )
                })
            }
            return arrayList;
        }
    }
    hasQuickAction = (callSupport, emailSupport) => {
        if (this.props.trackingDetails && this.props.trackingDetails.processUpdateForm && !isEmpty(JSON.parse(this.props.trackingDetails.processUpdateForm).formList) ||
            this.props.trackingDetails && this.props.trackingDetails.pudo && this.props.trackingDetails.pudo.pudoScreen === true && this.props.pudoPoints && !isEmpty(this.props.pudoPoints) ||
            callSupport ||
            emailSupport) {
            return true;
        }
        return false;
    }
    render() {
        let callSupport, emailSupport,emailSubject="";
        if (this.props.trackingDetails && this.props.trackingDetails.help && !isEmpty(this.props.trackingDetails.help)) {
            let help = JSON.parse(this.props.trackingDetails.help);
            if (help.helpLineContact && !isEmpty(help.helpLineContact) && help.helpLineContact.length > 2) {
                callSupport = help.helpLineContact;
            }
            if (help.helpLineEmail && !isEmpty(help.helpLineEmail) && help.helpLineEmail.length > 2) {
                emailSupport = help.helpLineEmail;
            }
            if(help.helpLineEmailSubject && !isEmpty(help.helpLineEmailSubject)){
                emailSubject =  help.helpLineEmailSubject;
             }
        }
        return (
            <>
                <div className='mobile-scroll'>
                    <ul className='ci-pudo-detail-quick-action-boxes' id="quickActionDesktop">

                        {this.getProcessFormList(this.props.fontSize, this.props.fontFamily)}
                        {callSupport ? <a href={"tel:" + callSupport} style={{ fontFamily: this.props.fontFamily, fontSize: this.props.fontSize }}>
                            <li className='ci-order-quick-action' style={{ display: "flex", padding: "10px" }}>
                                <span ><Call fill={this.props.primaryBgColor} /></span>
                                <p style={{ marginLeft: "16px" }}>{containerConstants.formatString(containerConstants.CallSupport)}</p>
                            </li></a> : null}
                        {emailSupport ? <a href={`mailto:${emailSupport}?subject=${emailSubject}`} style={{ fontFamily: this.props.fontFamily, fontSize: this.props.fontSize }}>
                            <li button className='ci-order-quick-action' style={{ display: "flex", padding: "10px" }}>
                                <span id="emailButton"><Email fill={this.props.primaryBgColor} /></span>
                                <p style={{ marginLeft: "16px" }}>{containerConstants.formatString(containerConstants.EmailSupport)}</p>
                            </li></a> : null}
                    </ul>
                </div>
            </>
        );
    }
}

export default OrderDesktopQuickAction;


