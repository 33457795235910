const bulgarianStrings={
YourShipmentHasBeenDelivered:"Вашата пратка е доставена",
DeliveredBy:"Доставена от",
RateYourExperience:"Оценете изживяването си",
ThankYouForYourValuableFeedback:"Благодарим за ценната обратна информация",
YourFeedbackWillContinuallyHelpUsImproveOurServices:"Вашата обратна информация ни помага постоянно да подобряваме услугите си",
DeliveryFailed:"Неуспешна доставка",
AddDeliveryPreferences:"Добави предпочитания за доставка",
YourDeliveryPreferenceAreSavedSuccessFully:"Вашите предпочитания за доставка са успешно запазени",
WeAreUnableToTrackYourShipmentRightNow:"Не може да проследим пратката ви в момента.",
PleaseUpdateLocationInsideCircle:"Моля, актуализирайте местоположението в кръга",
Ok:"Добре",
Update:"Актуализация",
PickCurrentLocation:"Изберете текущото местоположение",
SearchFor:"Търся",
ThisSchedulingLinkHasExpired:"Тази връзка за планиране е изтекла",
WeWillShareANewLinkWithYouShortly:"Скоро ще споделим нова връзка с вас",
UhHo:"О, не!",
NeedHelp:"Имаш Ли Нужда От Помощ?",
CallSupport:"Обадете Се На Екипа За Поддръжка",
EmailSupport:"Изпратете имейл на поддръжката",
DeliveryAt:"Доставка в",
 DeliveredAt: 'Доставено в',
ContactNo:"Номер за контакт",
TrackOnMap:"Проследи на картата",
MessageToExecutive:"Съобщение до изпълнителя",
Cancel:"Отмени",
Send:"Изпрати",
Executive:"Изпълнител",
Comments:"Коментари (опция)",
SubmitFeedback:"ПОДАВАНЕ НА ОБРАТНА ИНФОРМАЦИЯ",
TrackingNo:"Проследяващ №",
Details:"Детайли",
Characters:"Символи",
Updating:'актуализиране…',
OopsSomethingWentWrongPleaseTryAfterSomeTime:'Олеле! Нещо се обърка, моля, опитайте пак след малко',
NotListedAboveSendACustomMessage:'Не е посочено по-горе, изпрати персонално съобщение',
ChangeDeliveryLocation:'Промяна на мястото за доставка',
AttemptedBy:'Опитано от',
DeliveredTo:'Доставена на',
SendMessage:'ИЗПРАТИ СЪОБЩЕНИЕ',
VIEW_DETAILS:'Преглед на детайли',
SAVE_LOCATION:'ЗАПАЗИ МЕСТОПОЛОЖЕНИЕ',
LOCATION:'местоположение',
SEARCH_ADDRESS:'Търсене на адрес',
OpenHours:'Работно време',
Note:'Бележка',
Address:'Адрес',
Nearest: 'Най-близо',
ContactNumber:'Номер за контакт',
FAQS:'ЧЗВ',
HowManyTimesCanIChangeMyPickUpPoint: 'Колко пъти мога да сменям точката си за взимане',
YouCanChangeItOnly: 'Можете да я смените само',
GoBack:'Назад',
SetAsPickupStore:'Задаване като магазин за взимане',
MapView:'Изглед на картата',
SearchForPickupPointNearYou:'Търсене на точка за взимане близо до вас',
Arriving:'Пристига',
LiveTrackingWillStart:'Ще започне проследяване на живо',
DriverTrackerWillGetActivatedOnceTheyAreOnWay:  'Тракерът на водача ще се активира след като тръгне',
TrackerTimeline:'График на таймера',
PM:'PM',
AM:'AM',
TodayAt:'днес в',
TomorrowAt:'утре в',
Today:'Днес',
Tomorrow:'Утре',
On:'на',
In:'в',
Soon:'скоро',
Min:'мин',
Mins:'минути',
Items:'АРТИКУЛИ',
ViewDetails: 'Преглед на детайли',
DeliveryInformation:'Информация за доставка',
TrackingHistory:'История на проследяване',
StayConnected:'Остани свързан',
YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'Вече сте опитали да направите плащане в последните няколко минути. Искате ли да отмените предишни транзакции',
No: 'НЕ',
yes:'ДА',
YourPackageWillBeDeliveredBy : 'Вашата пратка ще бъде доставена от',
OrderDetails: 'Детайли за поръчка',
OrderInformation:'Информация за поръчка',
LastAttempted:'Последен опит',
DeliveryBy:'ДоставкаДо',
Pickuppointisupdated:'Точката за взимане се актуализира',
MsgToFePageHeader:'Изпращане на инструкции за доставката',
MsgToFeSupportingText:'Какво искате да кажете на шофьора?',
AddYourMsgHere:'Добавете съобщението си тук',
InstructionsSentSuccessfully:'Инструкциите са успешно изпратени',
SomethingWentWrongPlsTryAgain:'Нещо се обърка, опитайте отново.',
times:"пъти",
TrackMovementInRealTime:"Проследяване на движението в реално време",
Hours:"hours",
Hour:"hour",
WriteYourFeedbackOptional:"Напишете отзивите си (По избор)",
ReturnOrder: "Return Order",
TheLinkYouAreTryingToOpenHasExpired: "Връзката, която се опитвате да отворите, е изтекла",
SelectAnAvailableTimeSlot:"Select an available time slot",
January:"JANUARY",
February:"FEBRUARY",
March :"MARCH",
April:"APRIL",
May:"MAY",
June:"JUNE",
July:"JULY",
August:"AUGUST",
September:"SEPTEMBER",
October:"OCTOBER",
November:"NOVEMBER",
December:"DECEMBER",
Monday:"MONDAY",
Tuesday:"TUESDAY",
Wednesday:"WEDNESDAY",
Thursday:"THURSDAY",
Friday:"FRIDAY",
Saturday:"SATURDAY",
Sunday:"SUNDAY",
ViewTimings: "View Timings",
HideTimings: "Hide Timings",
PleaseAnswerAllMandatoryQuestions:"Моля, отговорете на всички задължителни въпроси",
HelpLineLink: 'Helpline Link',
ShipmentOf: "Пратка%от",
OtherShipments:"Други Пратки",
OrderInformation:"Order Information",
OR: "или",
TrackShipment:"Проследяване на пратката",
TrackingDescription:"Въведете подробностите по-долу, за да получите подробности за вашата пратка",
Continue:"Продължи",
InvalidCredentials:"Невалидни данни за вход. Моля, опитайте отново"

}
export default bulgarianStrings;
