import { Record } from 'immutable';

const InitialState = Record({
    universalConfig : {},
    theme: "",
    newTheme:{},
    logo:"",
    loading:true,
    universalSearchBackgroundImageUrl:"",
    ciLink:""
});

export default new InitialState();