import React from 'react';
import {isEmpty} from 'loadsh';
import { enhancedLiveTrackingService } from '../services/EnhancedLiveTrackingService';
import { processUpdateFormService } from '../services/ProcessUpdateFormService';
import CONSTANTS from '../Utils/Constants';
import { commonUtilities } from '../services/commonUtilities';

class ArrivingEta extends React.Component {
    monthNames = [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ];
    componentDidMount(){
        var basicInformtion = document.getElementById('etaBasicInfo')
    if (basicInformtion && this.props.preview) {
      const buttonForEdit = commonUtilities.createButtonElement();
      basicInformtion.classList.add("borderHover");
      basicInformtion.insertBefore(buttonForEdit, basicInformtion.firstChild);
      buttonForEdit.addEventListener("click", () => {
        window.parent.postMessage("basicInformtion", "*");
      })
    }
    }

      getEta = (deliveryDate) => {
        let currentDate = new Date();
        if (deliveryDate && !isEmpty(deliveryDate)) {
            let eta = new Date(deliveryDate.replace(/-/g, "/"));
            if (deliveryDate.length > 12) {
                let actualEta = eta.getDate() + " " + this.monthNames[eta.getMonth()] + " ";
                let t = eta.getHours() >= 12 ?(eta.getHours==12?eta.getHours():eta.getHours() - 12) +":" +(eta.getMinutes() > 9 ?
                        eta.getMinutes() :"0" + eta.getMinutes()) +
                    containerConstants.formatString(containerConstants.PM) :
                    eta.getHours() +
                    ":" +
                    (eta.getMinutes() > 9 ?eta.getMinutes() :
                    "0" + eta.getMinutes()) +
                    containerConstants.formatString(containerConstants.AM);
                actualEta = actualEta + " " + t;
                if (this.props.trackingDetails.liveTrackingEnableTime) {
                    let enableEta1 = cloneDeep(eta);
                    let trackingEnableETA = enableEta1.setMinutes(
                        enableEta1.getMinutes() -
                        this.props.trackingDetails.liveTrackingEnableTime
                    );
                    if (new Date().getTime() < trackingEnableETA) {
                        let diffTime = Math.abs(trackingEnableETA - new Date().getTime());
                        let enableEta = Math.ceil(diffTime / (1000 * 60));
                        if (this.state.trackEnableTime == -1) {
                            this.setState({
                                trackEnableTime: enableEta
                            });
                        }
                    }
                }
                if (currentDate.getMonth() == eta.getMonth()) {
                    if (currentDate.getDate() == eta.getDate()) {
                        return (
                            containerConstants.formatString(containerConstants.TodayAt) + t
                        );
                    } else if (currentDate.getDate() + 1 === eta.getDate()) {
                        return (
                            containerConstants.formatString(containerConstants.TomorrowAt) + t
                        );
                    }
                }
                return (
                    containerConstants.formatString(containerConstants.On) + actualEta
                );
            } else {
                if (currentDate.getMonth() == eta.getMonth()) {
                    if (currentDate.getDate() == eta.getDate()) {
                        return containerConstants.formatString(containerConstants.Today);
                    } else if (currentDate.getDate() + 1 === eta.getDate()) {
                        return containerConstants.formatString(containerConstants.Tomorrow);
                    }
                }
                return (
                    containerConstants.formatString(containerConstants.On) +
                    eta.getDate() +
                    " " +
                    this.monthNames[eta.getMonth()] +
                    " "
                );
            }
        }
    };
    getEtaBrowserTimeSingle = (deliveryDate) => {
        var timeFormat = processUpdateFormService.getTimeFormat(this.props.trackingDetails);
        if (deliveryDate && !isEmpty(deliveryDate)) {
          let eta;
          if(this.props.trackingDetails.browserTimeZone && this.props.trackingDetails.browserTimeZone==true){
             eta = new Date(deliveryDate.replace(/-/g, "/")+" UTC");
          }
          else{
             eta = new Date(deliveryDate.replace(/-/g, "/"));
          }
          if(timeFormat==CONSTANTS.TWENTYFOUR_HOUR_FORMAT){
            var hours = eta.getHours() ;
            var minutes = eta.getMinutes() < 10 ? '0' + eta.getMinutes() : eta.getMinutes();
            return  hours + ":" + minutes;
        }
            var hours = eta.getHours() ; // gives the value in 24 hours format
            var AmOrPm = hours >= 12 ? containerConstants.formatString(containerConstants.PM):containerConstants.formatString(containerConstants.AM);
            hours = (hours % 12) || 12;
            // hours = hours < 10 ? '0'+hours : hours;
            var minutes = eta.getMinutes() < 10 ? '0' + eta.getMinutes() : eta.getMinutes();
            var finalTime = hours + ":" + minutes + " " + AmOrPm; 
            return finalTime
        }
      
       }

    getEtaBrowserTime=(etaFromServer)=>{
        let currentDate = new Date();
        let etaString = etaFromServer.split("xwwx");
        let etaStringLeft = this.getEtaBrowserTimeSingle(etaString[0]);
        let etaStringRight = this.getEtaBrowserTimeSingle(etaString[1]);
        let etaR = new Date(etaString[0].replace(/-/g, "/")+" UTC");
        if(etaString[2]==true){
        if (currentDate.getMonth() == etaR.getMonth()) {
            if (currentDate.getDate() == etaR.getDate()) {
                return containerConstants.formatString(containerConstants.Today);
            } else if (currentDate.getDate() + 1 === etaR.getDate()) {
                return containerConstants.formatString(containerConstants.Tomorrow);
            }
        }
        else{
        return (
            containerConstants.formatString(containerConstants.On) +
            etaR.getDate() +
            " " +
            this.monthNames[etaR.getMonth()] +
            " "
        );}}
        else{
        let finalTime;
        if (currentDate.getMonth() == etaR.getMonth()) {
            if (currentDate.getDate() == etaR.getDate()) {
                finalTime = containerConstants.formatString(containerConstants.TodayAt)+" ";
            } else if (currentDate.getDate() + 1 === etaR.getDate()) {
                finalTime = containerConstants.formatString(containerConstants.TomorrowAt)+" ";
            }
        }
        else{
            finalTime =
            containerConstants.formatString(containerConstants.On) +
            etaR.getDate() +
            " " +
            this.monthNames[etaR.getMonth()] +
            " ,";
        } 
        if(etaString[1] && etaString[1]=="kk"){
            finalTime = finalTime + etaStringLeft;
        }
        else{
            finalTime = finalTime + etaStringLeft +" - "+ etaStringRight;
        } 
        
        return finalTime;
        }
        

    }   

    checkValidETA = (deliveryDate) => {
        if (deliveryDate && !isEmpty(deliveryDate)) {
            let eta = new Date(deliveryDate.replace(/-/g, "/"));
            if (eta != undefined && eta != "undefined" && eta != null 
                && eta != "Invalid Date") {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }


    getETAString = (etaFromServer) => {
        if (isEmpty(etaFromServer)) return null;
        if (this.props.trackingDetails.layoutScreen.etaSource && this.props.trackingDetails.layoutScreen.etaSource == "TRIP_ETA") {

            if (this.props.tripEta && this.props.tripEta.actualEta && !((this.props.tripEta.actualEta).indexOf("undefine") != -1 || (this.props.tripEta.actualEta).indexOf("Na") != -1
                || (this.props.tripEta.time).indexOf("undefine") != -1 || (this.props.tripEta.time).indexOf("Na") != -1)) {
                var actualETA = "";
                var time = "";
                try {
                    if (this.props.tripEta.actualEta.indexOf("Today") != -1) {
                        actualETA = " " + actualETA + containerConstants.formatString(containerConstants.TodayAt) + " ";
                    } else if (this.props.tripEta.actualEta.indexOf("Tomorrow") != -1) {
                        actualETA = " " + actualETA + containerConstants.formatString(containerConstants.TomorrowAt) + " ";
                    } else {
                        actualETA = "";
                        let array = this.props.tripEta.actualEta.trim().split(" ");
                        for (let i = 0; i < array.length; i++) {
                            if (array[i].indexOf("on") != -1) {
                                array[i] = containerConstants.formatString(containerConstants.On);
                            }
                        }
                        for (let i = 0; i < array.length; i++) {
                            actualETA = actualETA + array[i] + " ";
                        }
                        actualETA = actualETA.trim() + ", ";
                    }

                    let arr = this.props.tripEta.time.split(" ");
                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i].indexOf("PM") != -1 || arr[i].indexOf("AM") != -1) {
                            arr[i] = arr[i].indexOf("PM") != -1 ? containerConstants.formatString(containerConstants.PM) : containerConstants.formatString(containerConstants.AM);
                        }
                    }


                    for (let i = 0; i < arr.length; i++) {
                        time = time + arr[i] + " ";
                    }
                } catch (e) {
                    console.error(e);
                }

                return actualETA + time;

            }
            return null;
        }
        if(this.props.trackingDetails.browserTimeZone && this.props.trackingDetails.browserTimeZone==true && !(this.props.trackingDetails.layoutScreen.etaSource && (this.props.trackingDetails.layoutScreen.etaSource=="PROCESS_ATTRIBUTE"))) {
            return this.getEtaBrowserTime(etaFromServer);
        }
        else if (etaFromServer.split(" ").length == 2) {
          // this is to handle untill the eta from server code is deployed on mobi
          const valid = this.checkValidETA(etaFromServer);
          return valid ? this.getEta(etaFromServer) : null;
        }
         else {
          if(etaFromServer && (etaFromServer.indexOf("today")!=-1 || etaFromServer.indexOf("tomorrow")!=-1)){
                let arr = etaFromServer.split(" ");
                for(let i=0;i<arr.length;i++){
                    if(arr[i] && !isEmpty(arr[i])){
                          if((arr[i].indexOf("today")!=-1 || arr[i].indexOf("tomorrow")!=-1)){
                                if(arr[i+1] && !isEmpty(arr[i+1]) && arr[i+1].indexOf("at")!=-1){
                                  arr[i] = arr[i].indexOf("today")!=-1?containerConstants.formatString(containerConstants.TodayAt):containerConstants.formatString(containerConstants.TomorrowAt);
                                  arr[i+1]="";
                              } else {
                                arr[i] = arr[i].indexOf("today")!=-1?containerConstants.formatString(containerConstants.Today):containerConstants.formatString(containerConstants.Tomorrow);
                              }
                          }  else if(arr[i].indexOf("PM")!=-1 || arr[i].indexOf("AM")!=-1){
                            arr[i]=arr[i].indexOf("PM")!=-1?containerConstants.formatString(containerConstants.PM):containerConstants.formatString(containerConstants.AM);
                          }
                          
                    }
                }
                etaFromServer ="";
                for(let i=0;i<arr.length;i++){
                   etaFromServer = etaFromServer+arr[i]+" ";
                }
          }
          return " " + etaFromServer;
        }
      };


    getV3Eta=()=>{
        //<div className='mt10 widget1-header'>
        //         <p className='fs14 bold'>Estimated Time of Delivery</p>
        //         <p className='bold fs28 header-eta'>Arriving on Mon, 29 December</p>
        //     </div>
        let isTripETA=this.props.trackingDetails.layoutScreen.etaSource=="TRIP_ETA";
        return (
            <div className='ci-v3eta widget1-header'>
                <p className="fs16 light-grey label"
                  style={{
                    color:this.props.newCiData?.eta?.etaHeading?.fontColour,
                    fontSize:this.props.newCiData?.eta?.etaHeading?.fontSize,
                    fontWeight:this.props.newCiData?.eta?.etaHeading?.fontWeight,
                    fontFamily:`${this.props.newCiData?.eta?.etaHeading?.fontType||"Roboto"},sans-serif`,
                }}
                >
                {this.props.trackingDetails.layoutScreen.etaHeading?this.props.trackingDetails.layoutScreen.etaHeading:''}
                </p>
                <p
                className="pb5 bold fs21 text-black header-eta"
                // style={{ color: this.props.secondaryTextColor }}
                style={{
                    color:this.props.newCiData?.eta?.eddValue?.fontColour,
                    fontSize:this.props.newCiData?.eta?.eddValue?.fontSize,
                    fontWeight:this.props.newCiData?.eta?.eddValue?.fontWeight,
                    fontFamily:`${this.props.newCiData?.eta?.eddValue?.fontType||"Roboto"},sans-serif`,
                }}
                >
                {this.props.isReturn?containerConstants.formatString(containerConstants.Pickup):containerConstants.formatString(containerConstants.Arriving)}{" "}
                {this.getETAString(isTripETA?this.props.tripEta:this.props.trackingDetails.layoutScreen.eta)}
                </p>
          </div>
        )
    }

    getLiveTrackingEta=(eta)=>{

        const mapObj = {
            min: containerConstants.formatString(containerConstants.Min) ?  containerConstants.formatString(containerConstants.Min) : "min",
            mins: containerConstants.formatString(containerConstants.Mins)? containerConstants.formatString(containerConstants.Mins) :"mins",
            hours: containerConstants.formatString(containerConstants.Hours) ? containerConstants.formatString(containerConstants.Hours):"hours",
            hour: containerConstants.formatString(containerConstants.Hour) ? containerConstants.formatString(containerConstants.Hour):"hour"          
          };
        eta = eta && eta.replace(/\b(?:min|mins|hours|hour)\b/gi, matched => mapObj[matched]);

        return (
            <div>
            <p className="fs16 light-grey"
             style={{
                color:this.props.newCiData?.eta?.etaHeading?.fontColour,
                fontSize:this.props.newCiData?.eta?.etaHeading?.fontSize,
                fontWeight:this.props.newCiData?.eta?.etaHeading?.fontWeight,
            }}
            >
                {this.props.trackingDetails.layoutScreen.etaHeading?this.props.trackingDetails.layoutScreen.etaHeading:''}
                </p>
             <p
                className="pb5 bold fs21 text-black "
                // style={{ color: this.props.secondaryTextColor }}
                style={{
                    color:this.props.newCiData?.eta?.eddValue?.fontColour,
                    fontSize:this.props.newCiData?.eta?.eddValue?.fontSize,
                    fontWeight:this.props.newCiData?.eta?.eddValue?.fontWeight,
                }}
                >

                {containerConstants.formatString(containerConstants.Arriving) ? containerConstants.formatString(containerConstants.Arriving) : "Arriving"}
                {" "}
                {containerConstants.formatString(containerConstants.In) ? containerConstants.formatString(containerConstants.In) : "in"}
                {" "}
                {eta}
            </p>
            </div>
        )
    }

    getLiveTrackingCacheEta=()=>{
        if(this.props.trackingDetails.preventGoogleLiveTrackingETA){
            return;
        }
        if(this.props.trackingDetails.showEta &&  this.props.trackingDetails.showTracking && !isEmpty(this.props.eta)){
            return this.props.eta;
        } else if( this.props.trackingDetails.showEta &&  this.props.trackingDetails.showTracking && this.props.trackingDetails.liveMonitoringDto && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList 
            && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0] && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].duration 
            && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].duration.text){
            let cache_data;
            let details = this.props.trackingDetails;
            if(this.props.trackingDetails && !isEmpty(this.props.trackingDetails.predictiveTrackingInfo)) {
                cache_data = enhancedLiveTrackingService.handleTrackingWithCacheData(this.props.trackingDetails);
            } else {
                cache_data = window.localStorage.getItem('cache_data');
                cache_data = JSON.parse(cache_data);
                const url = this.props.url;
                cache_data = ((cache_data && cache_data[url]  && cache_data[url]["userId"]==details.userId)?cache_data[url]:undefined);
            }
            return (cache_data && !isEmpty(cache_data["dateTime"]))?cache_data["eta"]: this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].duration.text; 
        } 
    }

    checkIfjobClosed=()=>{
        return (this.props.trackingDetails && this.props.trackingDetails.liveMonitoringDto && this.props.trackingDetails.liveMonitoringDto.isJobToRemove) || (this.props.trackingDetails && this.props.trackingDetails.jobRemoved) 
                || this.props.isJobRemoved
    }
    checkJobTransactionTracking=()=>{
        if(this.props.trackingDetails.layoutScreen.etaSource && this.props.trackingDetails.layoutScreen.etaSource=="JOB_TRANSACTION"){
            return this.props.trackingDetails.layoutScreen.trackingEnabled;
        }
        return true;
    }

    render(){
         if(this.props.trackingDetails && !this.props.trackingDetails.showEta){
          return (<></>);
         }
        let eta = this.getLiveTrackingCacheEta();
        let isTripETA=this.props.trackingDetails.layoutScreen.etaSource=="TRIP_ETA";

        return (
            <div id="etaBasicInfo" className={this.props.isMobileView?"etaMobile":''}>
                { 
                  !this.checkIfjobClosed()?
                  eta!=undefined && !isEmpty(eta) && !(this.props.trackingDetails.preventGoogleLiveTrackingETA) && (this.checkJobTransactionTracking())?
                     this.getLiveTrackingEta(eta):
                     !isEmpty( this.getETAString(isTripETA?this.props.tripEta:this.props.trackingDetails.layoutScreen.eta)) ? 
                     this.getV3Eta()
                       : null
                       :null
                }
           </div>
        );
    }
}

export default ArrivingEta;



