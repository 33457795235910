import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { isEmpty } from 'loadsh'
import { MyContext } from '../context';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { FormControl, FormControlLabel, Popover, Radio, RadioGroup, Typography } from '@material-ui/core';
import countryNamesAndCodes, { COUNTRY_LOCALIZATION_MAP } from '../../Utils/countryConstants';


class UniversalHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openLangModal: false,
      isIframe: false
    }
  }
  componentDidMount = () => {
    if (window.location.href.indexOf('&iframe=true') != -1) {
      this.setState({
        isIframe: true,
      })
    }
  }


  getNeedHelpButtonText = () => {
    if (this.props.themeData) {
      if (this.props.themeData.helpSectionText && !isEmpty(this.props.themeData.helpSectionText)) {
        return this.props.themeData.helpSectionText;
      }
    }
    return containerConstants.formatString(containerConstants.NeedHelp);
  }
  isShowNeedHelp = (callSupport, emailSupport, isFormAvailable, isPudo, showSupportDetail, helpLineLink) => {
    if (this.props.isSurveyFormNeedHelp) {
      if (showSupportDetail && (callSupport || emailSupport || helpLineLink)) {
        return true;
      }
    }
    else if (((callSupport || emailSupport || isFormAvailable || isPudo || this.isCurbsidePickup() || helpLineLink) && this.props.needHelp) && !this.props.customScreen) {
      return true;
    }

    return false;
  }

  onLanguageSelection = (langCode) => {
    if (isEmpty(langCode) || isEmpty(COUNTRY_LOCALIZATION_MAP[langCode])) {
      console.log('empty or invalid langCode');
      return;
    }
    sessionStorage.setItem("lang", langCode);
    window.sessionStorage.setItem(
      "languageDirection",
      countryNamesAndCodes[window.sessionStorage.lang]
    );
    location.reload();

  }
  getLanguageSelection = () => {
    var selectedLanguage = sessionStorage.getItem("lang") || this.props.language;
    return (
      <>
        <Popover
          id={"1"}
          open={this.state.openLangModal}
          anchorEl={this.state.anchorEl}
          onClose={() => this.setState({ openLangModal: false })}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          className="lang-selection-popover"
          style={this.props?.theme || {}}
        >
          <div className="lang-list">
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={selectedLanguage}
                onChange={(e) => {
                  this.onLanguageSelection(e.target.value)
                }}
              >
                {Object.values(COUNTRY_LOCALIZATION_MAP).map((countryDetails) => {
                  if (this.props.themeData.languageCodeList?.includes(countryDetails.code))
                    return (
                      <FormControlLabel
                        style={{ fontSize: "12px" }}
                        key={countryDetails.code}
                        value={countryDetails.code}
                        className="form-label"
                        control={<Radio style={{ fontSize: "12px" }} />}
                        label={<Typography variant="h6" style={{ fontSize: "12px",fontFamily: `${this.props.fontType||"Roboto"},sans-serif`}} >{countryDetails.name} - {countryDetails.code.toUpperCase()}</Typography>}
                      />
                    );
                })}
              </RadioGroup>
            </FormControl>
          </div>
        </Popover>
      </>
    )
  }
  render() {
    const { ctaFontColor, ctaColorWhite } = this.props.themeData;
    var selectedLanguage = sessionStorage.getItem("lang") || "";
    return (
      <MyContext.Consumer>
        {({ primaryBgColor, primaryTextColor, fontSize, fontFamily, secondaryBgColor, useAsPrimaryColour, headerColor }) => {
          let textColor = primaryTextColor != undefined ? primaryTextColor : "white";
          let logo = this.props.logo;
          if (logo && logo.includes("images/logo_app.png")) {
            logo = require('../../images/logo_app.png');
          }
          return (
            <div className="header bg-primary"
              style={{
                backgroundColor: useAsPrimaryColour ? primaryBgColor : isEmpty(headerColor) ? primaryBgColor : headerColor,
                color: textColor,
                fontSize: fontSize,
                borderBottom: '1px solid #DADADA'
              }}>
              <div className="HeaderInner flex width-100 align-center relative">
                {<div className="company-logo-div">{logo && <img src={logo} className="company-logo-img" />}</div>}
                {
                  (<span className="need_help_box" style={{ display: "flex" }} >
                    {!isEmpty(this.props.themeData?.languageCodeList) &&
                      <button
                        style={{
                          backgroundColor: (ctaColorWhite ? "#ffffff" : secondaryBgColor),
                          color: (ctaFontColor ? ctaFontColor : (primaryTextColor ? primaryTextColor : primaryBgColor)),
                        }}
                        className="returns-lang-button"
                        onClick={(e) => { this.setState({ openLangModal: true, anchorEl: e.currentTarget }) }}
                      >
                        <LanguageOutlinedIcon style={{ fontSize: 16,}} />
                        {selectedLanguage && (<span className="lang-code" style={{fontFamily: `${this.props.fontType||"Roboto"},sans-serif`}}> {selectedLanguage} </span>)}
                        {<span
                          className="dropdown-icon"
                          style={this.state.openLangModal ? { transform: "rotate(180deg)",fontFamily: `${this.props.fontType||"Roboto"},sans-serif`} : {}}
                        >
                          {<KeyboardArrowDownSharpIcon />}
                        </span>}
                      </button>
                    }

                    {
                      <div >
                        <Button id="helpbutton" onClick={this.props.setHelpModel}
                          style={{
                            backgroundColor: (ctaColorWhite ? "#ffffff" : secondaryBgColor),
                            color: (ctaFontColor ? ctaFontColor : (primaryTextColor ? primaryTextColor : primaryBgColor)),
                            fontSize: fontSize,
                            whiteSpace: "nowrap",
                            fontFamily:`${this.props.fontType||"Roboto"},sans-serif`,
                          }}>
                          {this.getNeedHelpButtonText()}
                        </Button>
                      </div>
                    }
                  </span>
                  )
                }
              </div>
              {this.getLanguageSelection()}
            </div>
          )
        }}

      </MyContext.Consumer>
    )
  }
}

function mapStateToProps(state) {
  return {
    trackingDetails: state.customerInteractionReducer.trackingDetails,
  };
}

export default connect(mapStateToProps)(UniversalHeader);
