import React, { Component } from 'react';
import { ChangeDeliveryIcon, UpdateContactIcon} from "../PudoStore/Icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Call, Email, Edit, Pudo } from '../HelpSection/icons';
import { isEmpty } from 'loadsh'

// Import Swiper styles

class MobileQuickAction extends Component {
    getProcessFormList = (fontSize, fontFamily) => {
        if (!isEmpty(this.props.trackingDetails) && this.props.trackingDetails.processUpdateForm) {
            let forms = JSON.parse(this.props.trackingDetails.processUpdateForm);
            let arrayList = [];
            if (forms && forms.formList && !isEmpty(forms.formList)) {
                (forms.formList).map((form, index) => {
                    if (form.introText)
                        arrayList.push(
                            <SwiperSlide>
                                <div className='ci-pudo-detail-quick-action-delivery' button key={index} onClick={this.props.openProcessForm(index)}>
                                    <span><Edit/></span>
                                        {form.introText.includes(' ') ?
                                            <p>{form.introText.length > 32 ? <>{form.introText.substring(0, 32)}&hellip;</> : form.introText}</p>
                                            :
                                            <p>{form.introText.length > 13 ? <>{form.introText.substring(0, 13)}&hellip;</> : form.introText}</p>
                                        }
                                    </div>
                            </SwiperSlide>
                        )
                })
            }
            return arrayList;
        }
    }

    showPudoOption=()=>{
       return (<>
        {(this.props.trackingDetails && this.props.trackingDetails.pudo && this.props.trackingDetails.pudo.pudoScreen === true) &&
            <SwiperSlide  id="pudoShopQuickAction" onClick={(e)=>this.props.openPudoScreen(e)}>
                <div className='ci-pudo-detail-quick-action-delivery'> 
                <span component="span" id="pudoShopQuickAction" > <Pudo fill={this.props.primaryBgColor} /></span>
                    <p className="collectPoints" id="pudoShopQuickAction">
                        {this.props.pudoTitle ?
                            <p className="fs14" id="pudoShopQuickAction">{this.props.pudoTitle.length > 32 ? <>{this.props.pudoTitle.substring(0, 32)}&hellip;</> : this.props.pudoTitle}</p>
                            :
                            <p className="fs14" id="pudoShopQuickAction">Collect from pickup point</p>
                        }
                        {/* {this.props.subLabel ? this.props.subLabel.indexOf("{STORE}") != -1 ?
                            <p className="fs12">{this.props.subLabel.substring(0, this.props.subLabel.indexOf("{STORE}")) + ' ' + this.props.pudoPoints.length + '' + this.props.subLabel.substring(this.props.subLabel.indexOf("{STORE}") + 7)}</p>
                            :
                            <p className="fs12">{this.props.subLabel}{' '}{this.props.pudoPoints.length}</p>
                            :
                            <p className="fs12">Select from over {this.props.pudoPoints.length} store(s) around you</p>} */}

                    </p>
                    </div>
            </SwiperSlide>
         }</>
       );
    }
    hasQuickAction=(callSupport, emailSupport)=>{
        if(this.props.trackingDetails && this.props.trackingDetails.processUpdateForm && !isEmpty(JSON.parse(this.props.trackingDetails.processUpdateForm).formList) ||
        this.props.trackingDetails && this.props.trackingDetails.pudo && this.props.trackingDetails.pudo.pudoScreen === true && this.props.pudoPoints && !isEmpty(this.props.pudoPoints) ||
        callSupport ||
        emailSupport)
        {
            return true;
        }
        return false;
    }

  render() {
    let callSupport, emailSupport,emailSubject="";
    if (this.props.trackingDetails && this.props.trackingDetails.help && !isEmpty(this.props.trackingDetails.help)) {
        let help = JSON.parse(this.props.trackingDetails.help);
        if (help.helpLineContact && !isEmpty(help.helpLineContact) && help.helpLineContact.length > 2) {
            callSupport = help.helpLineContact;
        }
        if (help.helpLineEmail && !isEmpty(help.helpLineEmail) && help.helpLineEmail.length > 2) {
            emailSupport = help.helpLineEmail;
        }
        if(help.helpLineEmailSubject && !isEmpty(help.helpLineEmailSubject)){
            emailSubject =  help.helpLineEmailSubject;
         }

    }
    return (
        <>
        <h4 className='mobileQuickAction'>QUICK ACTION</h4>
        <div className='mobile-scroll' id="quickActionMobile">
            <Swiper
                slidesPerView={2.4}
                spaceBetween={16}
                className="mySwiper">
                {this.getProcessFormList(this.props.fontSize, this.props.fontFamily)}
                {this.showPudoOption()}

                {callSupport ?
                    <SwiperSlide>
                        <a href={"tel:" + callSupport} style={{ fontFamily: this.props.fontFamily, fontSize: this.props.fontSize }}>
                            <div className='ci-pudo-detail-quick-action-delivery'>
                            <span component="span"><Call fill={this.props.primaryBgColor} /></span>
                            <p>{containerConstants.formatString(containerConstants.CallSupport)}</p>
                        </div>
                        </a>
                    </SwiperSlide>: null}
                {emailSupport ? 
                    <SwiperSlide>
                       <a href={`mailto:${emailSupport}?subject=${emailSubject}`} style={{ fontFamily: this.props.fontFamily, fontSize: this.props.fontSize }}>
                       <div button className='ci-pudo-detail-quick-action-delivery'>
                            <span component="span" id="emailButton"><Email fill={this.props.primaryBgColor} /></span>
                            <p>{containerConstants.formatString(containerConstants.EmailSupport)}</p>
                        </div>
                       </a>
                    </SwiperSlide>: null}
            </Swiper>
        </div>
        </>
    );
  }
}

export default MobileQuickAction;