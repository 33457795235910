import axios from 'axios';
import { isEmpty } from 'lodash';
import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import HelpSection from '../../components/HelpSection';
import SocialMedia from '../../components/SocialMedia';
import { containerConstantsService } from '../../Utils/containerConstants';
import { MyContext } from '../context';
import InputFields from './InputFields';
import UniversalHeader from './UniversalHeader';
import { getUniversalSearchConfig } from './UniversalSearchAction';
import UNIVERSAL_SEARCH from '../../Utils/universalSearchConstants';
import { commonUtilities } from '../../services/commonUtilities';
import TableLoader from '../../components/TableLoader';
import { getFontList } from '../../Utils/themeConfig';

class UniversalSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNeedHelpDialog: false
    }
    this.queryParams = new URLSearchParams(location.search);
    this.encodedString = this.queryParams.get(UNIVERSAL_SEARCH.ENCODED_STRING);
    this.languageCode = this.queryParams.get(UNIVERSAL_SEARCH.LANG) || sessionStorage.getItem(UNIVERSAL_SEARCH.LANG);
    commonUtilities.setLanguageInSession(this.languageCode);
    this.merchantCode = this.queryParams.get(UNIVERSAL_SEARCH.MERCHNAT_CODE) || "";
    this.paramsList = this.createParamList();
    this.hideCompanentsList = this.queryParams.get(UNIVERSAL_SEARCH.HIDE);
    this.hideCompanentsList = this.hideCompanentsList && this.hideCompanentsList.split(",");
    this.showHeader = true;
    this.showFooter = true;
    this.showBackground = true;
    if (this.hideCompanentsList && this.hideCompanentsList.includes(UNIVERSAL_SEARCH.HEADER)) {
      this.showHeader = false;
    }
    if (this.hideCompanentsList && this.hideCompanentsList.includes(UNIVERSAL_SEARCH.FOOTER)) {
      this.showFooter = false;
    }
    if (this.hideCompanentsList && this.hideCompanentsList.includes(UNIVERSAL_SEARCH.BACKGROUND)) {
      this.showBackground = false;
    }
    



  }
  setHelpModel = () => {
    this.setState({ showNeedHelpDialog: true });
  };
  closeHelpModal = () => {
    this.setState({ showNeedHelpDialog: !this.state.showNeedHelpDialog })
  }
  async componentDidMount() {
    await this.props.actions.getUniversalSearchConfig(this.encodedString, this.merchantCode, this.languageCode,);
      let fonts=getFontList(this.props.newTheme);
      if (fonts){
        // Create the font link element
        this.fontLink = document.createElement('link');
        this.fontLink.href = `https://fonts.googleapis.com/css?family=${fonts}`;
        this.fontLink.rel = 'stylesheet';
        document.head.appendChild(this.fontLink);
      }
  }
  componentWillMount() {
    if (this.fontLink) {
        document.head.removeChild(this.fontLink);
    }
  }
  createParamList = () => {
    let paramsList = {};
    if (this.queryParams.get(UNIVERSAL_SEARCH.PARAM1)) {
      paramsList.param1 = this.queryParams.get(UNIVERSAL_SEARCH.PARAM1);
    }
    if (this.queryParams.get(UNIVERSAL_SEARCH.PARAM2)) {
      paramsList.param2 = this.queryParams.get(UNIVERSAL_SEARCH.PARAM2);
    }
    if (this.queryParams.get(UNIVERSAL_SEARCH.PARAM3)) {
      paramsList.param3 = this.queryParams.get(UNIVERSAL_SEARCH.PARAM3);
    }
    if (this.queryParams.get(UNIVERSAL_SEARCH.PARAM4)) {
      paramsList.param4 = this.queryParams.get(UNIVERSAL_SEARCH.PARAM4);
    }
    if (this.queryParams.get(UNIVERSAL_SEARCH.PARAM5)) {
      paramsList.param5 = this.queryParams.get(UNIVERSAL_SEARCH.PARAM5);
    }

    return paramsList;
  }
  render() {
    let languageData = this.languageCode ? containerConstantsService.getInstance(this.languageCode) : containerConstantsService.getInstance("en");
    window.containerConstants = languageData;
    const theme = this.props?.theme ? JSON.parse(this.props.theme) : {};
    const universalConfigAttributeList = this?.props?.universalConfig ? this.props.universalConfig : {};
    if(this.props.loading){
      return <TableLoader/>
    }
    return (
      <MyContext.Provider
        value={{
          primaryBgColor: theme && theme.primaryBgColor,
          primaryTextColor: theme && theme.primaryTextColor,
          secondaryBgColor: theme && theme.secondaryBgColor,
          secondaryTextColor: theme && theme.secondaryTextColor,
          fontSize: theme && theme.fontSize,
          fontFamily: theme && theme.fontFamily,
          useAsPrimaryColour: theme && theme.useAsPrimaryColour,
          headerColor: theme && theme.headerColor
        }}>
          <div className="ci-top universal"  style={{
            background:  this.showBackground && this.props.universalSearchBackgroundImageUrl?`url(${this.props.universalSearchBackgroundImageUrl})`:'white',
            backgroundRepeat:"no-repeat",
            backgroundSize:"cover",
            backgroundPosition:"center",
          }}>
            {this.showHeader && <UniversalHeader
              themeData={theme}
              logo={this.props.logo}
              setHelpModel={this.setHelpModel}
              fontType={this.props.newTheme?.defaultSettings?.typeface?.fontType}
            />}
            <div className="universal-bg">
              <InputFields
                universalConfigAttributeList={universalConfigAttributeList}
                paramsList={this.paramsList}
                encodedString={this.encodedString}
                themeData={theme}
                newThemeData={this.props.newTheme}
                ciLink={this.props.ciLink}
                />
             </div>
            {this.state.showNeedHelpDialog &&
              <HelpSection
              showNeedHelpDialog={true}
              calledFromUniversal={true}
              setHelpSection={this.closeHelpModal}
              callSupport={theme.supportContactNumber}
              emailSupport={theme.supportEmail}
              newThemeData={this.props.newTheme}
              />}
            {this.showFooter && <SocialMedia
              theme={theme}
              language={this.languageCode || "en"}
              fontFamily={`${this.props.newTheme?.defaultSettings?.typeface?.fontType||"Roboto"},sans-serif`}
            />}
        </div>
      </MyContext.Provider>
    )
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { getUniversalSearchConfig },
      dispatch
    ),
  };
}

function mapStateToProps(state) {
  return {
    universalConfig: state.universalSearchReducer.universalConfig,
    theme: state.universalSearchReducer.theme,
    newTheme: state.universalSearchReducer.newTheme,
    data: state.universalSearchReducer.data,
    logo: state.universalSearchReducer.logo,
    loading: state.universalSearchReducer.loading,
    universalSearchBackgroundImageUrl: state.universalSearchReducer.universalSearchBackgroundImageUrl,
    ciLink: state.universalSearchReducer.ciLink,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UniversalSearch);
