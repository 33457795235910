const vietnameseStrings={
    YourShipmentHasBeenDelivered:'Lô hàng của bạn đã được giao',
DeliveredBy:'Người giao',
RateYourExperience:'Đánh giá trải nghiệm của bạn',
ThankYouForYourValuableFeedback:'Cảm ơn ý kiến phản hồi quý giá của bạn',
YourFeedbackWillContinuallyHelpUsImproveOurServices:'Ý kiến phản hồi của bạn sẽ không ngừng giúp chúng tôi cải thiện dịch vụ',
DeliveryFailed:'Không giao hàng được',
AddDeliveryPreferences:'Thêm tùy chọn giao hàng',
YourDeliveryPreferenceAreSavedSuccessFully:'Tùy chọn giao hàng của bạn đã được lưu thành công',
WeAreUnableToTrackYourShipmentRightNow:'Chúng tôi không thể theo dõi lô hàng của bạn lúc này',
PleaseUpdateLocationInsideCircle:'Vui lòng cập nhật vị trí bên trong vòng tròn',
Ok:'Ok',
Update:'Cập nhật',
PickCurrentLocation:'Chọn vị trí hiện tại',
SearchFor:'Tìm kiếm',
ThisSchedulingLinkHasExpired:'Liên kết đặt lịch này đã hết hạn',
WeWillShareANewLinkWithYouShortly:'Chúng tôi sẽ sớm chia sẻ liên kết mới với bạn',
UhHo:'Rất tiếc!',
NeedHelp:'Bạn cần trợ giúp?',
CallSupport:'Gọi cho bộ phận hỗ trợ',
EmailSupport:'Gửi email cho bộ phận hỗ trợ',
DeliveryAt:'Giao hàng tại',
DeliveredAt: 'Giao hàng đến',
ContactNo:'Số liên hệ',
TrackOnMap:'Theo dõi trên bản đồ',
MessageToExecutive:'Nhắn tin cho Nhân viên điều hành',
Cancel:'Hủy',
Send:'Gửi',
Executive:'Nhân viên điều hành',
Comments:'Nhận xét (không bắt buộc)',
SubmitFeedback:'GỬI Ý KIẾN PHẢN HỒI',
TrackingNo:'Số theo dõi',
Details:'Thông tin chi tiết',
Characters:'Ký tự',
Updating:'đang cập nhật.....',
OopsSomethingWentWrongPleaseTryAfterSomeTime:'Chưa có trong danh sách ở trên, hãy gửi một tin nhắn tùy chỉnh',
NotListedAboveSendACustomMessage:'Thay đổi vị trí giao hàng',
ChangeDeliveryLocation:'Người thử',
AttemptedBy:'Đã giao tới',
DeliveredTo:'GỬI TIN NHẮN',
SendMessage:'Xem chi tiết',
VIEW_DETAILS:'LƯU VỊ TRÍ',
SAVE_LOCATION:'vị trí',
LOCATION:'Tìm kiếm địa chỉ',
SEARCH_ADDRESS:'Giờ mở cửa',
OpenHours:'Ghi chú',
Note:'Địa chỉ',
Address:'Gần nhất',
Nearest: 'Số liên hệ',
ContactNumber:'Câu hỏi thường gặp',
FAQS:'Câu hỏi thường gặp',
HowManyTimesCanIChangeMyPickUpPoint: 'Tôi có thể thay đổi điểm lấy hàng bao nhiêu lần?',
YouCanChangeItOnly: 'Bạn chỉ có thể thay đổi',
GoBack:'Quay lại',
SetAsPickupStore:'Chế độ xem bản đồ',
MapView:'Chế độ xem bản đồ',
SearchForPickupPointNearYou:'Tìm kiếm điểm lấy hàng gần bạn',
Arriving:'Đang đến',
LiveTrackingWillStart:'Dòng thời gian của trình theo dõi',
DriverTrackerWillGetActivatedOnceTheyAreOnWay:  'Trình theo dõi tài xế sẽ được kích hoạt sau khi họ lên đường',
TrackerTimeline:'chiều',
PM:'sáng',
AM:'hôm nay lúc',
TodayAt:'ngày mai lúc',
TomorrowAt:'Hôm nay',
Today:'Ngày mai',
Tomorrow:'Tomorrow',
On:'vào',
In:'trong',
Soon:'sớm',
Min:'phút',
Mins:'phút',
Items:'MẶT HÀNG',
ViewDetails: 'Xem chi tiết',
DeliveryInformation:'Thông tin giao hàng',
TrackingHistory:'Lịch sử theo dõi',
StayConnected:'Duy trì kết nối',
YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'Bạn đã tìm cách thanh toán trong vài phút qua. Bạn có muốn hủy giao dịch trước không',
No: 'KHÔNG',
yes:'CÓ',
YourPackageWillBeDeliveredBy : 'Gói hàng của bạn sẽ được giao bởi',
OrderDetails: 'Chi tiết đơn hàng',
OrderInformation:'Thông tin đơn hàng',
LastAttempted:'Lần thử cuối cùng',
DeliveryBy:'Giao hàng bởi',
Pickuppointisupdated:'Điểm đón đã được cập nhật',
MsgToFePageHeader:'Gửi hướng dẫn giao hàng',
MsgToFeSupportingText:'Quý vị muốn gửi thông tin gì đến tài xế?',
AddYourMsgHere:'Thêm tin nhắn của quý vị ở đây',
InstructionsSentSuccessfully:'Gửi hướng dẫn thành công',
SomethingWentWrongPlsTryAgain:'Có điều gì đó không đúng, xin làm lại.',
times:"lần",
TrackMovementInRealTime:"Theo dõi di chuyển theo thời gian thực",
Hours:"hours",
Hour:"hour",
WriteYourFeedbackOptional:"Viết phản hồi của bạn (Tùy chọn)",
ReturnOrder: "Return Order",
TheLinkYouAreTryingToOpenHasExpired: "Liên kết bạn đang cố mở đã hết hạn",
SelectAnAvailableTimeSlot:"Select an available time slot",
January:"JANUARY",
February:"FEBRUARY",
March :"MARCH",
April:"APRIL",
May:"MAY",
June:"JUNE",
July:"JULY",
August:"AUGUST",
September:"SEPTEMBER",
October:"OCTOBER",
November:"NOVEMBER",
December:"DECEMBER",
Monday:"MONDAY",
Tuesday:"TUESDAY",
Wednesday:"WEDNESDAY",
Thursday:"THURSDAY",
Friday:"FRIDAY",
Saturday:"SATURDAY",
Sunday:"SUNDAY",
ViewTimings: "View Timings",
HideTimings: "Hide Timings",
PleaseAnswerAllMandatoryQuestions:"Vui lòng trả lời tất cả các câu hỏi bắt buộc",
HelpLineLink: 'Helpline Link',
ShipmentOf: "Giao hàng%của",
OtherShipments: "Các Lô Hàng Khác",
OrderInformation:"Order Information",
OR:"hoặc",
TrackShipment:"Theo dõi lô hàng",
TrackingDescription:"Nhập chi tiết bên dưới để nhận thông tin chi tiết về lô hàng của bạn",
Continue:"Tiếp tục",
InvalidCredentials:"Thông tin đăng nhập không hợp lệ. Vui lòng thử lại"
}
export default vietnameseStrings;