import React, { Fragment } from "react";
import { isEmpty } from "loadsh";
import { getAttributeTypeFromValue } from "../../Utils/attributeTypeUtil";
import { ATTRIBUTE_TYPE } from "../../Utils/Constants";
import { convertFromStandardFormat } from "../../Utils/dateTimeUtil";
import { Col, Row } from "antd";

function getAttributeValueByType(attributeValue, attributeType, props) {
  if(isEmpty(attributeValue) || isEmpty(attributeType) || isEmpty(props)) {
    return attributeValue;
  }

  const theme = props.trackingDetails && props.trackingDetails.customerInteractionThemeSettingsDTO;

  if(attributeType == ATTRIBUTE_TYPE.DATE || attributeType == ATTRIBUTE_TYPE.DATE_TIME) {
    return convertFromStandardFormat(attributeValue, theme && theme.dateFormat);
  }

  return attributeValue;
}
function checkIflink(value){
  return ((!isEmpty(value))) && ((value.indexOf("http://") == 0 || value.indexOf("https://") == 0));
 }
function checkIfPdf(value){
  return ((value).split('.').reverse()[0])=="pdf";
 }

function OrderInformation(props) {
  return (
    <div className="orderInfo">
      {props.arrayList &&
        !isEmpty(props.arrayList) &&
        Object.values(props.arrayList).map(function (element, index) {
          let orderinfoAttribute =props.newCiData?.orderInfo?.attributes;
          let orderinfoValue =props.newCiData?.orderInfo?.value;
          return (
            <div  className="imgaeArray">
              <Row>
                <Col span={8}>
                  {element.itemImage && <div style={{ height: "5rem", width: "5rem" }}>
                    <img src={element.itemImage} style={{ height: "100%", width: "100%" }}></img>
                  </div>}
                </Col>
                <Col span={element.itemImage ? 16 : 24}>
                  <div>
                    {element.itemName && <h3>{element.itemName}</h3>}
                    {element.itemQuantity != 0 && 
                      (<span>
                         <p 
                          style={{
                            fontWeight:orderinfoAttribute && orderinfoAttribute.fontWeight,
                            color:orderinfoAttribute && orderinfoAttribute.textColour,
                            fontFamily: props.newCiData?.orderInfo?.headerText?.fontType ? props.newCiData?.orderInfo?.headerText?.fontType : props.newCiData?.defaultSettings?.typeface?.fontType,
                            display: "inline-block"
                           }}
                           >Qty: </p>
                       <p 
                        style={{
                          fontWeight: orderinfoValue && orderinfoValue.fontWeight,
                          color: orderinfoValue && orderinfoValue.textColour,
                          fontFamily: props.newCiData?.orderInfo?.headerText?.fontType ? props.newCiData?.orderInfo?.headerText?.fontType : props.newCiData?.defaultSettings?.typeface?.fontType,
                          display: "inline-block"
                        }}
                        > {element.itemQuantity}</p>
                     </span>)
                    }
                    {Object.values(element.attributeValueList).map((attribute) => {
                      return (
                       <Fragment>
                          {attribute.label && attribute.value && 
                           <span>
                            <p 
                             style={{
                              fontWeight:orderinfoAttribute && orderinfoAttribute.fontWeight,
                              color:orderinfoAttribute && orderinfoAttribute.textColour,
                              fontFamily: props.newCiData?.orderInfo?.headerText?.fontType ? props.newCiData?.orderInfo?.headerText?.fontType : props.newCiData?.defaultSettings?.typeface?.fontType,
                              display: "inline-block"
                            }}
                             >{attribute.label}:</p>
                             <p 
                              style={{
                                display: "inline-block",
                                fontWeight: orderinfoValue && orderinfoValue.fontWeight,
                                color: orderinfoValue && orderinfoValue.textColour,
                                fontFamily: props.newCiData?.orderInfo?.headerText?.fontType ? props.newCiData?.orderInfo?.headerText?.fontType : props.newCiData?.defaultSettings?.typeface?.fontType,
                              }}
                              > {attribute.value}</p>
                           </span>
                         }
                        </Fragment>
                      )
                    })}
                  </div>
                </Col>
              </Row>
              <hr/>
            </div>
          );

        })}
      {props.attributeList &&
        !isEmpty(props.attributeList) &&
        Object.values(props.attributeList).map(function (attribute, index) {
           if(attribute && attribute.value && !isEmpty(attribute.value)){
            let etAttribute =props.newCiData?.eta?.attributes;
            let etValue =props.newCiData?.eta?.value;
            const attributeType = getAttributeTypeFromValue(attribute.value);
            const attributeValue = getAttributeValueByType(attribute.value, attributeType, props);
            return (
              <div className="box py15" key={index}>
                <span className="ci-order-label-title"
                   style={{
                    fontWeight:etAttribute && etAttribute.fontWeight,
                    color:etAttribute && etAttribute.textColour,
                  }}>
                  {attribute.label}
                </span>
                <span
                  className="ci-order-value-title"
                  // style={{ color: props.secondaryTextColor }}
                  style={{
                    fontWeight: etValue && etValue.fontWeight,
                    color: etValue && etValue.textColour,
                  }}
                >
                  {checkIflink(attributeValue) || checkIfPdf(attributeValue)?
                        <a id="myLink" href={attributeValue} target="_blank">{checkIfPdf(attributeValue)?"view Document":attributeValue}</a>
                    :attributeValue}
                </span>
              </div>
            );
           }
        })}
        {props.orderAttributeList &&
        !isEmpty(props.orderAttributeList) &&
        Object.values(props.orderAttributeList).map(function (attribute, index) {
           if(attribute && attribute.value && !isEmpty(attribute.value)){
            let orderinfoAttribute =props.newCiData?.orderInfo?.attributes;
            let orderinfoValue =props.newCiData?.orderInfo?.value;
            const attributeType = getAttributeTypeFromValue(attribute.value);
            const attributeValue = getAttributeValueByType(attribute.value, attributeType, props);

            return (
              <div className="p10 pl0 box" key={index}>
                <span className="ci-order-label-title" 
                 style={{
                  fontWeight:orderinfoAttribute && orderinfoAttribute.fontWeight,
                  color:orderinfoAttribute && orderinfoAttribute.textColour,
                }}
                >
                  {attribute.label}
                </span>
                <span
                  className="ci-order-value-title"
                  // style={{ color: props.secondaryTextColor }}
                  style={{
                    fontWeight: orderinfoValue && orderinfoValue.fontWeight,
                    color: orderinfoValue && orderinfoValue.textColour,
                  }}
                >
                  {checkIflink(attributeValue) || checkIfPdf(attributeValue)?
                        <a id="myLink" href={attributeValue} target="_blank">{checkIfPdf(attributeValue)?"view Document":attributeValue}</a>
                    :attributeValue}
                </span>
              </div>
            );
           }
        })}
    </div>
  );
}

export default OrderInformation;