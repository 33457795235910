import React from 'react';
import './style.css';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { isEmpty } from 'loadsh'
import { MyContext } from '../../containers/context';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { FormControl, FormControlLabel, Popover, Radio, RadioGroup, Typography } from '@material-ui/core';
import countryNamesAndCodes, { COUNTRY_LOCALIZATION_MAP } from '../../Utils/countryConstants';
import { storeClickCountService } from '../../StoreClickCountService';
import StoreClickCountLabelConstants from '../../Utils/StoreClickCountlabelConstants';

class Header extends React.Component {

  constructor(props){
    super(props);
    this.state={
      openLangModal:false,
      isIframe:false
    }
    this.fontType=`${this.props.trackingDetails.newLayout?this.props.trackingDetails?.customerInteractionThemeSettingsDTO?.newCiData?.defaultSettings?.typeface?.fontType:"Roboto"},sans-serif`
  }
  componentDidMount = () =>{
    if(window.location.href.indexOf('&iframe=true')!=-1){
      this.setState({
        isIframe:true,
      })
    }
  }
  isCurbsidePickup = () => {
    return this.props.trackingDetails && this.props.trackingDetails.type === "CURBSIDE_PICKUP" ? true : false;
  }

  getNeedHelpButtonText = () => {
    if (this.props.trackingDetails) {
      if (this.isCurbsidePickup()) {
        return this.props.helpSectionText;
      } else if (this.props.trackingDetails.needHelp && !isEmpty(this.props.trackingDetails.needHelp)) {
        return this.props.trackingDetails.needHelp;
      }
    }
    return containerConstants.formatString(containerConstants.NeedHelp);
  }
  isShowNeedHelp = (callSupport, emailSupport, isFormAvailable, isPudo, showSupportDetail, helpLineLink) => {
    if (this.props.isSurveyFormNeedHelp) {
      if (showSupportDetail && (callSupport || emailSupport || helpLineLink)) {
        return true;
      }
    }
    else if (((callSupport || emailSupport || isFormAvailable || isPudo || this.isCurbsidePickup() || helpLineLink) && this.props.needHelp) && !this.props.customScreen) {
      return true;
    }

    return false;
  }

  onLanguageSelection = (langCode)=>{
    if(isEmpty(langCode) || isEmpty(COUNTRY_LOCALIZATION_MAP[langCode])){
      console.log('empty or invalid langCode');
      return;
    }
    sessionStorage.setItem("lang",langCode);
    window.sessionStorage.setItem(
      "languageDirection",
      countryNamesAndCodes[window.sessionStorage.lang]
    );
    location.reload();

  }
  clickCountForLanguage=(label)=>{

      storeClickCountService.storeClickedButtonCount(label,StoreClickCountLabelConstants.LANGUAGE_CHANGED,this.props.url,this.props.merchantCode,this.props.isReturn);
    // }
  }
  getLanguageSelection=()=>{
    var selectedLanguage = sessionStorage.getItem("lang") || this.props.trackingDetails?.language;
    return (
      <>
      <Popover
        id={"1"}
        open={this.state.openLangModal}
        anchorEl={this.state.anchorEl}
        onClose={() => this.setState({ openLangModal: false })}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className="lang-selection-popover"
        style={this.props?.theme ||{}}
      >
        <div className="lang-list">
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={selectedLanguage}
              onChange={(e) => {
                this.onLanguageSelection(e.target.value)
              }}
            >
              {Object.values(COUNTRY_LOCALIZATION_MAP).map((countryDetails) => {
                if (this.props.trackingDetails?.customerInteractionThemeSettingsDTO?.languageCodeList?.includes(countryDetails.code))
                  return (
                    <FormControlLabel
                      onClick={()=>this.clickCountForLanguage(countryDetails.name)}
                      style={{ fontSize: "12px" }}
                      key={countryDetails.code}
                      value={countryDetails.code}
                      className="form-label"
                      control={<Radio style={{fontSize: "12px"}} />}
                      label={<Typography variant="h6" style={{fontSize: "12px",fontFamily:this.fontType}} >{countryDetails.name} - {countryDetails.code.toUpperCase()}</Typography> }
                    />
                  );
              })}
            </RadioGroup>
          </FormControl>
        </div>
      </Popover>
      </>
    )
  }
  render() {
    if(this.state.isIframe){
      return <></>;
    }
    const { ctaFontColor, ctaColorWhite } = this.props
    let callSupport, emailSupport, isFormAvailable, isPudo, showSupportDetail, helpLineLink;
    if (this.props.trackingDetails && !isEmpty(this.props.trackingDetails)) {
      if (this.props.trackingDetails.processUpdateForm) {
        let forms = JSON.parse(this.props.trackingDetails.processUpdateForm);
        if (forms && forms.formList && !isEmpty(forms.formList)) {
          isFormAvailable = true;
        }
      }
      if (this.props.trackingDetails.pudo && this.props.trackingDetails.pudo.pudoScreen === true) {
        isPudo = true
      }
      if (this.props.trackingDetails.help && !isEmpty(this.props.trackingDetails.help)) {
        let help = JSON.parse(this.props.trackingDetails.help);
        if (help.helpLineContact && !isEmpty(help.helpLineContact) && help.helpLineContact.length > 2) {
          callSupport = help.helpLineContact;
        } else if (help.helpLineEmail && !isEmpty(help.helpLineEmail) && help.helpLineEmail.length > 2) {
          emailSupport = help.helpLineEmail;
        } else if (help.helpLineLink && !isEmpty(help.helpLineLink) && help.helpLineLink.length > 2) {
          helpLineLink = help.helpLineLink;
        }

        if (help.showSupportDetail != undefined) {
          showSupportDetail = help.showSupportDetail;
        }
      }
    }
    var selectedLanguage = sessionStorage.getItem("lang") || this.props.trackingDetails?.language;
    return (
      <MyContext.Consumer>
        {({ primaryBgColor, primaryTextColor, fontSize, fontFamily, secondaryBgColor, secondaryTextColor, useAsPrimaryColour, headerColor }) => {
          let mobileView = this.props.mobileView != undefined ? this.props.mobileView : false;
          let textColor = primaryTextColor != undefined ? primaryTextColor : "white";
          let logo = this.props.logo;
          if (logo && logo.includes("images/logo_app.png")) {
            logo = require('../../images/logo_app.png');
          }
          if (this.props.isV3 || (mobileView == false && this.props.deviceType == false) || (this.props.landingLayout) || (this.props.trackingDetails.layoutScreen && !isEmpty(this.props.trackingDetails.layoutScreen))) {
            //deskTopView header
            return (
              <div className="header bg-primary"
                style={{
                  backgroundColor: useAsPrimaryColour ? primaryBgColor : isEmpty(headerColor) ? primaryBgColor : headerColor,
                  color: textColor,
                  fontSize: fontSize,
                  fontFamily: fontFamily,
                  borderBottom: '1px solid #DADADA'
                }}>
                <div className="HeaderInner flex width-100 align-center relative">
                  {this.props.back ? <div onClick={this.props.backScreen} className="logo-back-click">
                    <IconButton size="medium" aria-label="Delete">
                      {this.props.customBackIcon ? this.props.customBackIcon() :
                        (window.sessionStorage.getItem('languageDirection') == "rtl" ?
                          <ArrowForwardIosIcon className="fs24" style={{ color: textColor }} /> : <ArrowBackIosIcon className="fs24" style={{ color: textColor }} />)}
                    </IconButton>
                    {logo && <div className="company-logo-div"><img src={logo} className="company-logo-img" /></div>}
                  </div> :
                    <div className="company-logo-div">{logo && <img src={logo} className="company-logo-img" />}</div>
                  }
                  {this.props.title ? <span className={this.props.curbsidePickup ? "text-left width80" : "text-center width80 inner-heading-title"}>
                    {this.props.title}
                  </span> : null}
                  <span className="need_help_box" style={{display:"flex"}} > 
                    {!isEmpty(this.props.trackingDetails?.customerInteractionThemeSettingsDTO?.languageCodeList) &&
                    <button
                      style={{
                        backgroundColor: (ctaColorWhite ? "#ffffff" : secondaryBgColor),
                        color: (ctaFontColor ? ctaFontColor : (primaryTextColor ? primaryTextColor : primaryBgColor)),
                      }}
                      className="returns-lang-button" 
                      onClick={(e) => { this.clickCountForLanguage(StoreClickCountLabelConstants.OPEN_LANGUAGE_CHANGE_BOX);this.setState({ openLangModal: true, anchorEl: e.currentTarget }) }}
                      >
                      <LanguageOutlinedIcon style={{ fontSize: 16 }} />
                      {selectedLanguage && (<span className="lang-code" style={{fontFamily:this.fontType}}> {selectedLanguage} </span>)}
                      {<span
                        className="dropdown-icon"
                        style={this.state.openLangModal ? { transform: "rotate(180deg)" } : {}}
                      >
                        {<KeyboardArrowDownSharpIcon />}
                      </span>}
                    </button>
                    }
                    {(!this.props.quickAction || this.props.showNeedHelp) && this.isShowNeedHelp(callSupport, emailSupport, isFormAvailable, isPudo, showSupportDetail, helpLineLink) ?
                       <div id={"helpButtonDiy"} className={this.props.isMobileType?"helpButtonDiyMobile":"helpButtonDiy"}>
                         <Button id="helpbutton" onClick={this.props.setHelpModel}
                          style={{
                            backgroundColor: (ctaColorWhite ? "#ffffff" : secondaryBgColor),
                            color: (ctaFontColor ? ctaFontColor : (primaryTextColor ? primaryTextColor : primaryBgColor)),
                            fontSize: fontSize,
                            whiteSpace: "nowrap",
                            fontFamily:this.fontType
                          }}>
                          {this.getNeedHelpButtonText()}
                        </Button>
                       </div> : null   
                    }
                    </span>
                </div>
                {this.getLanguageSelection()}
              </div>
            )
          }
          return (
            <div className="header bg-primary"
              style={{
                backgroundColor: useAsPrimaryColour ? primaryBgColor : isEmpty(headerColor) ? primaryBgColor : headerColor,
                color: textColor,
                fontSize: fontSize,
                fontFamily: fontFamily,
                borderBottom: '1px solid #DADADA'
              }}>
              <div className="HeaderInner flex width-100 align-center relative">
                {this.props.back ? <div onClick={this.props.backScreen} className="logo-back-click">
                  <IconButton size="medium" aria-label="Delete">
                    {this.props.customBackIcon ? this.props.customBackIcon() :
                      (window.sessionStorage.getItem('languageDirection') == "rtl" ?
                        <ArrowForwardIosIcon className="fs24" style={{ color: textColor }} /> : <ArrowBackIosIcon className="fs24" style={{ color: textColor }} />)}
                  </IconButton>
                  {logo && <div className="company-logo-div" style={{ marginLeft: "50px" }}><img src={logo} className="company-logo-img" /></div>}
                </div> :
                  <div className="company-logo-div">{logo && <img src={logo} className="company-logo-img" />}</div>
                }
                {this.props.title ? <span className="text-center width80 inner-heading-title">
                  {this.props.title}
                </span> : null}
                <span className="need_help_box" style={{display:"flex"}}>
                  {!isEmpty(this.props.trackingDetails?.customerInteractionThemeSettingsDTO?.languageCodeList) &&
                  <button
                    style={{
                      backgroundColor: (ctaColorWhite ? "#ffffff" : secondaryBgColor),
                      color: (ctaFontColor ? ctaFontColor : (primaryTextColor ? primaryTextColor : primaryBgColor)),
                    }}
                    className="returns-lang-button" 
                    onClick={(e) => { this.clickCountForLanguage(StoreClickCountLabelConstants.OPEN_LANGUAGE_CHANGE_BOX);this.setState({ openLangModal: true, anchorEl: e.currentTarget }); }}
                    >
                    <LanguageOutlinedIcon style={{ fontSize: 16 }} />
                    {selectedLanguage && (<span className="lang-code" style={{fontFamily:this.fontType}}> {selectedLanguage} </span>)}
                    {<span
                      className="dropdown-icon"
                      style={this.state.openLangModal ? { transform: "rotate(180deg)" } : {}}
                    >
                      {<KeyboardArrowDownSharpIcon />}
                    </span>}
                  </button>}
                {(!this.props.quickAction || this.props.showNeedHelp) &&
                    this.isShowNeedHelp(callSupport, emailSupport, isFormAvailable, isPudo, showSupportDetail, helpLineLink) ?
                      <div><Button onClick={this.props.setHelpModel}
                        style={{
                          backgroundColor: (ctaColorWhite ? "#ffffff" : secondaryBgColor),
                          color: (ctaFontColor ? ctaFontColor : (primaryTextColor ? primaryTextColor : primaryBgColor)),
                          fontSize: fontSize,
                          fontFamily:this.fontType,
                          whiteSpace: "nowrap"
                        }}>
                        {this.getNeedHelpButtonText()}
                      </Button></div> : null 
                  }
                  </span>
              </div>
              {this.getLanguageSelection()}
            </div>
          );
        }}

      </MyContext.Consumer>
    )
  }
}

function mapStateToProps(state) {
  return {
    trackingDetails: state.customerInteractionReducer.trackingDetails,
  };
}

export default connect(mapStateToProps)(Header);
