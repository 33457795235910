import { combineReducers } from 'redux'
import customerInteractionReducer from './containers/ProcessUpdateForm/Reducer'
import pudoReducer from './containers/AvailableLocation/pudoReducer'
import curbsidePickupReducer from './containers/CurbsidePickupContainer/redux/curbsidePickupReducer'
import universalSearchReducer from './containers/UniversalTracking/universalSearchReducer'

export default combineReducers({
  customerInteractionReducer,
  pudoReducer,
  curbsidePickupReducer,
  universalSearchReducer,
})