import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as CustomerInteractionAction from "../../CustomerInteractionActions";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import CarouselComponent from "../../components/Carousel";
import SocialMedia from "../../components/SocialMedia";
import Grid from "@material-ui/core/Grid";
import OrderInformation from "../../components/OrderInformation";
import AddressDetails from "../../components/AddressDetails";
import StatusTimeLine from "../../components/StatusTimeLine";
import { processUpdateFormService } from "../../services/ProcessUpdateFormService";
import * as LivetrackingAction from "../LiveTracking/LiveTrackingAction";
import EmbedTracking from "../LiveTracking/EmbedTracking";

import FieldExecutiveDetail from "../../components/FieldExecutiveDetail";
import MessageToFE from '../../components/MessageToFE'
import Snackbar from '@material-ui/core/Snackbar';
import ArrivingEta from "../../components/ArrivingEta";
import ReturnInstruction from "../../components/ReturnInstruction";
import { isEmpty, cloneDeep } from "loadsh";





import FeedbackV3 from "../FeedbackV3";

import 'antd/dist/antd.css';
import { Button, Carousel as Carousel1 } from 'antd';
// import { RightOutlined } from '@ant-design/icons'
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { isEqual } from "lodash";
import IframeGoogleDocs from "../../components/GoogleDocViewer";
import { mediaLinkService } from "../../services/MediaLinkService";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import CIPudoMapDesktopModal from "../../containers/LayoutScreen/PudoReturnMap/CIPudoMapDesktopModal";
import PudoStoreDetails from "../../components/PudoStore/PudoStoreDetails";
import DesktopQuickAction from "../../components/QuickAction/DesktopQuickAction";
import { getPudoData } from "../MapView/MapViewAction"
import { listUtilities } from "../../services/ListUtilities";
import { commonUtilities } from "../../services/commonUtilities";
import { RightArrow } from "../ProcessUpdateForm/Icons";
import { ShipmentIcon, shipmentIcon } from "../../components/HelpSection/icons";
import OrderDesktopQuickAction from "../../components/QuickAction/OrderDeskTopQuickAction";
import ShipmentList from "../../components/Shipment";
import OrderDetails from "../../components/Shipment/details";
const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

class DesktopView extends Component {
  monthNames = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  constructor(props) {
    super(props);
    this.state = {
      marketingAssets: {},
      trackEnableTime: -1,
      message: false,
      isTripAPICalled: false,
      orderInfoDesk: false,
      trackerTimelineDesk: true,
      isEmbed: false,
    };

    this.getRefereshData = this.getRefereshData.bind(this);
    this.trackingDetailsRefreshTimeout = null;
  }
  componentWillMount() {
    if (window.location.href.indexOf('&embed=true') != -1) {
      this.setState({
        isEmbed: true,
      })
    }
  }
  async componentDidMount() {
    this.intervalId = setInterval(this.getRefereshData.bind(this), 5 * 60000);
    if (
      this.props.trackingDetails &&
      this.props.trackingDetails.jobId &&
      (!this.props.trackingDetails.layoutScreen || !this.props.trackingDetails.layoutScreen.eta)
    ) {
      var timeFormat = processUpdateFormService.getTimeFormat(this.props.trackingDetails);
      await this.props.actions.getTripETA(
        this.props.url,
        this.props.trackingDetails.jobId,
        this.props.trackingDetails.min,
        this.props.trackingDetails.max,
        this.props.trackingDetails.liveTrackingEnableTime,
        this.props.trackingDetails.browserTimeZone,
        timeFormat,
        this.props.trackingDetails.orderId,
      );
    }
    // this.intervalIdETa = setInterval(this.getTripETA.bind(this), 5 * 60000);
    if (!this.props.trackingDetails.calledFromDiy) {
      const marketingAssets = await processUpdateFormService.getMarketingAssets(this.props.url, null, this.props.isReturn);
      if (!isEmpty(marketingAssets)) {
        this.setState({ marketingAssets: marketingAssets });
      }
    }
    processUpdateFormService.checkGoogleAnalyticsCode(this.props.trackingDetails);
    this.checkPudoWithQuickAction();

    if (!isEmpty(this.props.trackingDetails.liveTrackingPreviousJobsInfo) && this.props.trackingDetails.liveTrackingPreviousJobsInfo.enabled) {
      this.previousJobsInfoIntervalId = setInterval(() => this.props.actions.getPreviousJobsInfo(this.props.url, this.props.trackingDetails), 60000);  // 60 sec

    }

  }

  checkPudoWithQuickAction = () => {
    if (this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO
      && this.props.trackingDetails.customerInteractionThemeSettingsDTO.quickAction
      && this.props.trackingDetails.pudo && this.props.trackingDetails.pudo.pudoScreen === true
      && isEmpty(this.props.pudoPoints)) {
      this.props.actions.getPudoData(
        this.props.url,
        this.props.trackingDetails.jobLat,
        this.props.trackingDetails.jobLng,
        this.props.trackingDetails.masterCode
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    clearInterval(this.intervalIdETa);
    clearInterval(this.previousJobsInfoIntervalId);
    if (this.trackingDetailsRefreshTimeout) {
      clearTimeout(this.trackingDetailsRefreshTimeout);
      this.trackingDetailsRefreshTimeout = null;
    }
  }


  async componentDidUpdate(nextProps) {
    if (isEmpty(this.state.marketingAssets) && this.props.trackingDetails.marketingAssetDIY) {
      this.setState({ marketingAssets: this.props.trackingDetails.marketingAssetDIY });
    }
    if (this.props.trackingDetails && this.props.trackingDetails.nextIntervalTime &&
      nextProps.trackingDetails.nextIntervalTime != this.props.trackingDetails.nextIntervalTime &&
      nextProps.trackingDetails.nextIntervalTime < this.props.trackingDetails.nextIntervalTime + 2000) {
      if (this.trackingDetailsRefreshTimeout) {
        clearTimeout(this.trackingDetailsRefreshTimeout);
        this.trackingDetailsRefreshTimeout = null;
      }

      this.trackingDetailsRefreshTimeout = setTimeout(this.getRefereshData.bind(this), this.props.trackingDetails.nextIntervalTime + 100);
    }
    // this will set overflow auto as set in componentDidMount after help modal close in case
    if (nextProps.showNeedHelpDialog && nextProps.showNeedHelpDialog != this.props.showNeedHelpDialog) {
      this.updateScroll();
    }


  }

  checkAndFetchFeedbackInfo = (prevProps) => {
    if (
      isEmpty(prevProps) ||
      isEmpty(prevProps.trackingDetails) ||
      isEmpty(this.props.trackingDetails) ||
      isEmpty(this.props.trackingDetails.feedback) ||
      isEmpty(this.props.url)
    ) {
      return;
    }

    if (!isEqual(prevProps.trackingDetails.feedback, this.props.trackingDetails.feedback) && !this.props.isFeedbackInfoFetch) {
      if(this.props.isReturn){
        this.props.actions.fetchFeedbackInfo(this.props.url,false,"",this.props.isReturn);
      }
      else{
        this.props.actions.fetchFeedbackInfo(this.props.url);
      }
    }
  }

  updateScroll() {
    setTimeout(function () {
    }.bind(this), 300);
  }

  getRefereshData() {
    // in case of live-tracking and fe movement no api call.
    if (this.props.url && this.props.trackingDetails && !(this.props.trackingDetails.liveMonitoringDto && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO
      && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0]
      && !isEmpty(this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0]) && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList
      && !isEmpty(this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList))) {
      this.props.actions.getTrackingDetails(this.props.url, this.props.isReturn, this.props.trackingDetails);
    }
  }



  setMessageModal = () => {
    this.setState({
      message: !this.state.message,
      openSendMsgSnack: false
    });
    this.updateScroll();
  }

  handleSuccessSendMessage = () => {
    this.setState({
      message: false,
      openSendMsgSnack: true
    });
  }
  getSendMsgResponse = () => {
    return (
      <div className="send-msg-snack-bar">
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.state.openSendMsgSnack}
          message={containerConstants.InstructionsSentSuccessfully}
          key="sendMsgSnack"
          autoHideDuration={3500}
          onClose={() => { this.setState({ openSendMsgSnack: false }) }}
        />
      </div>

    );
  }


  renderBannerAndCarsoul = () => {
    var marketingAssets = isEmpty(this.state.marketingAssets) ? this.props.trackingDetails?.marketingAssets : this.state.marketingAssets;
    if (!isEmpty(marketingAssets)) {
      return (
        <div className="desktop-view-market-asset newCiBackground" >
          <div className="desktop-view-market-box-space" id="marketingAsset">
            {!isEmpty(marketingAssets.bannerDetails) && marketingAssets.enableBanner && (
              <Banner
                image={
                  marketingAssets.bannerDetails.url
                }
                link={
                  marketingAssets.bannerDetails.redirectUrl
                }
              />
            )}
            {marketingAssets.enableCarousel && !isEmpty(marketingAssets.carouselDetails) &&
              (marketingAssets.carouselDetails.length > 2) && (
                <div className="carousel-maiDiv">
                  <div className="carousel-wrapper background-grey clearfix ">
                    <CarouselComponent
                      secondaryTextColor={this.props.secondaryTextColor}
                      carouselList={
                        marketingAssets && marketingAssets.carouselDetails
                      }
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
      );
    }
  };

  renderSocialMedia = () => {

    if (
      !isEmpty(this.props.trackingDetails) ||
      !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO) ||
      mediaLinkService.isFooterMediaLinkPresentInTheme(this.props.trackingDetails.customerInteractionThemeSettingsDTO)
    ) {
      return (
        <SocialMedia
          theme={this.props.trackingDetails.customerInteractionThemeSettingsDTO}
          layoutScreen={this.props.trackingDetails.layoutScreen}
          secondaryBgColor={this.props.secondaryBgColor}
        />
      );
    }


    if (this.props.trackingDetails && this.props.trackingDetails.layoutScreen) {
      let layoutScreen = this.props.trackingDetails.layoutScreen;
      if (!layoutScreen.facebook && !layoutScreen.twitter && !layoutScreen.youtube && !layoutScreen.insta && !layoutScreen.tiktok && !layoutScreen.linkedIn && !layoutScreen.pinterest
        && !(layoutScreen.extraInfo && layoutScreen.extraInfo[0] && layoutScreen.extraInfo[0].label)
        && !(layoutScreen.extraInfo && layoutScreen.extraInfo[1] && layoutScreen.extraInfo[1].label)) {
        return;
      }
    }

    return (
      <SocialMedia
        theme={this.props.trackingDetails.customerInteractionThemeSettingsDTO}
        layoutScreen={this.props.trackingDetails.layoutScreen}
        secondaryBgColor={this.props.secondaryBgColor}
      />
    );
  };



  ArrayPod = (feedback) => {
    let arr = [];
    if (feedback && feedback.podImageArrayList) {
      {
        for (let i = 0; i < feedback.podImageArrayList.length; i++) {

          if (!isEmpty(feedback.podImageArrayList[i]) && feedback.podImageArrayList[i].match(/\.pdf$/)) {
            arr.push(<div className="box relative">
              <div className="layer"></div>
              <div onClick={this.togglePopup.bind(this)}>
                {feedback.podImageArrayList[i].indexOf("https://") == 0 ?
                  <iframe
                    className="feedback-iframe"
                    src={feedback.podImageArrayList[i] + "#toolbar=0"}
                  /> :
                  <IframeGoogleDocs
                    url={feedback.podImageArrayList[i]}
                  />
                }

                <div className="feedback-iframe-layer"></div>
              </div>
            </div>)
          } else if (this.checkIfPodImageExist(feedback.podImageArrayList[i])) {
            arr.push(<div className="box">
              <div className="layer"></div>
              <img src={feedback.podImageArrayList[i]} onClick={this.togglePopup.bind(this)} className="" />
            </div>)
          }
        }
      }
      return arr;
    }
    else {
      return arr;
    }
  }
  renderCarousel = (feedback, status) => {
    if (this.ArrayPod(feedback).length >= 1) {
      return (
        <div className="feedback_carousel">
          <Carousel1
            arrows nextArrow={<span className="right_arrow"><FiChevronRight /></span>}
            prevArrow={<span className="left_arrow"><FiChevronLeft /></span>}
          >
            {this.ArrayPod(feedback)}
          </Carousel1>
        </div>
      )
    }
  }

  renderPodImages = (feedback) => {
    if (this.checkIfPodImageExist(feedback.podImage) && this.ArrayPod(feedback).length == 0) {
      if (feedback.podImage.match(/\.pdf$/)) {
        return (
          this.state.feedPopup ?
            <div className='feed_popup'>
              <div className="podsinglepop">
                {feedback.podImage.indexOf("https://") == 0 ?
                  <iframe
                    className="feedback-iframe"
                    src={feedback.podImage + "#toolbar=0"}
                  /> :
                  <IframeGoogleDocs
                    url={feedback.podImage}
                  />
                }

                <div className="cross align-center flex" onClick={this.togglePopup.bind(this)}><button><i class="bi bi-x-lg"></i>&nbsp; Close</button></div>
              </div>
            </div>
            :
            <div className="box relative">
              <div className="layer"></div>
              <div onClick={this.togglePopup.bind(this)}>
                {feedback.podImage.indexOf("https://") == 0 ?
                  <iframe
                    className="feedback-iframe"
                    src={feedback.podImage + "#toolbar=0"}
                  /> :
                  <IframeGoogleDocs
                    url={feedback.podImage}
                  />

                }

                <div className="feedback-iframe-layer"></div>
              </div>
            </div>

        );
      }

      return (
        this.state.feedPopup ?
          <div className='feed_popup'>
            <div className="podsinglepop box">
              <img width="100%" src={feedback.podImage} onClick={this.togglePopup.bind(this)} className="" />
              <div className="cross align-center flex" onClick={this.togglePopup.bind(this)}><button><i class="bi bi-x-lg"></i>&nbsp; Close</button></div>
            </div>
          </div>
          :
          <div className="relative">
            <div className="layer"></div>
            <img width="100%" src={feedback.podImage} onClick={this.togglePopup.bind(this)} className="" />
          </div>
      );
    }
    else if (feedback.podImageArrayList && !isEmpty(feedback.podImageArrayList) && feedback.podImageArrayList.length == 1 && this.checkIfPodImageExist(feedback.podImageArrayList[0])) {
      if (feedback.podImageArrayList[0].match(/\.pdf$/)) {
        return (
          this.state.feedPopup ?
            <div className='feed_popup'>
              <div className="podsinglepop">
                {feedback.podImageArrayList[0].indexOf("https://") == 0 ?
                  <iframe
                    className="feedback-iframe"
                    src={feedback.podImageArrayList[0] + "#toolbar=0"}
                  /> :
                  <IframeGoogleDocs
                    url={feedback.podImageArrayList[0]}
                  />
                }
                <div className="cross align-center flex" onClick={this.togglePopup.bind(this)}><button><i class="bi bi-x-lg"></i>&nbsp; Close</button></div>
              </div>
            </div>
            :
            <div className="box relative">
              <div className="layer"></div>
              <div onClick={this.togglePopup.bind(this)}>
                {feedback.podImageArrayList[0].indexOf("https://") == 0 ?
                  <iframe
                    className="feedback-iframe"
                    src={feedback.podImageArrayList[0] + "#toolbar=0"}
                  /> :
                  <IframeGoogleDocs
                    url={feedback.podImageArrayList[0]}
                  />
                }
                <div className="feedback-iframe-layer"></div>
              </div>
            </div>
        );
      }
      return (
        this.state.feedPopup ?
          <div className='feed_popup'>
            <div className="podsinglepop box">
              <img width="100%" src={feedback.podImageArrayList[0]} onClick={this.togglePopup.bind(this)} className="" />
              <div className="cross align-center flex" onClick={this.togglePopup.bind(this)}><button><i class="bi bi-x-lg"></i>&nbsp; Close</button></div>
            </div>
          </div>
          :
          <div className="relative">
            <div className="layer"></div>
            <img width="100%" src={feedback.podImageArrayList[0]} onClick={this.togglePopup.bind(this)} className="" />
          </div>
      );
    }
    else if (feedback.podImageArrayList && !isEmpty(feedback.podImageArrayList)) {
      return (
        this.state.feedPopup ?
          <div className='feed_popup'>
            {this.renderCarousel(feedback, true)}
            <div className="cross align-center flex" onClick={this.togglePopup.bind(this)}><button><i class="bi bi-x-lg"></i>&nbsp; Close</button></div>
          </div>
          :
          this.renderCarousel(feedback, false)
      );
    }
  }

  closeRattingScreenDrawer = () => {
    setTimeout(function () {
      document.body.style.overflowY = "auto";
    }.bind(this), 300);
  }

  checkIfPodImageExist = (img) => {
    return ((!isEmpty(img))) && ((img.indexOf("http://") == 0 || img.indexOf("https://") == 0));
  }

  checkFeedbackScreen = () => {
    if (!this.props.trackingDetails.feedback) {
      return;
    }

    let feedback = JSON.parse(this.props.trackingDetails.feedback);
    let marginTopVal = "0px";
    if (!((this.checkIfPodImageExist(feedback.podImage)) || this.ArrayPod(feedback).length >= 1)) {
      marginTopVal = "95px"
    }
    if (this.props.trackingDetails.screen == "feedbackScreen") {
      return (
        <div className="feedback_page" style={{ marginTop: marginTopVal, marginBottom: '20px' }}>
          {this.renderPodImages(feedback)}

          <div className="feedbackScreenNew" id="feedbackDiy">
            {this.props.trackingDetails.screen == "feedbackScreen" &&
              <FeedbackV3
                primaryBgColor={this.props.primaryBgColor}
                closeRattingScreenDrawer={this.closeRattingScreenDrawer}
                primaryTextColor={this.props.primaryTextColor}
                fontFamily={this.props.fontFamily}
                fontSize={this.props.fontSize}
                secondaryBgColor={this.props.secondaryBgColor}
                secondaryTextColor={this.props.secondaryTextColor}
                feedbackInfo={this.props.feedbackInfo}
                preview={this.props.preview}
              />}
          </div>
        </div>
      )
    }
  }
  hasQuickAction = () => {
    if ((this.props.trackingDetails && this.props.trackingDetails.processUpdateForm && !isEmpty(JSON.parse(this.props.trackingDetails.processUpdateForm).formList)) ||
      (this.props.trackingDetails && this.props.trackingDetails.pudo && this.props.trackingDetails.pudo.pudoScreen === true && this.props.pudoPoints && !isEmpty(this.props.pudoPoints)) ||
      (this.props.trackingDetails && this.props.trackingDetails.help && !isEmpty(this.props.trackingDetails.help) &&
        (!isEmpty(JSON.parse(this.props.trackingDetails.help).helpLineContact) || !isEmpty(JSON.parse(this.props.trackingDetails.help).helpLineEmail))
      )) {
      return true;
    }
    return false;
  }
  checkPudoScreen = () => {
    this.checkPudoWithQuickAction();
    this.props.openPudoScreen();
  }
  getDesktopQuickAction = () => {
    return (
      this.hasQuickAction() &&
      <div className="ci-pudo-detail-quick-div" style={{ borderRadius: "16px" }}>
        <OrderDesktopQuickAction
          showNeedHelpDialog={this.state.showNeedHelpDialog}
          setHelpSection={this.closeHelpModal}
          trackingDetails={this.props.trackingDetails}
          openProcessForm={this.props.openQuickActionForm}
          screen={this.props.trackingDetails.screen}
          pudoPoints={this.props.pudoPoints}
          openPudoScreen={() => this.checkPudoScreen()}
          pudoTitle={
            this.props.trackingDetails &&
            this.props.trackingDetails.pudo &&
            this.props.trackingDetails.pudo.title
          }
          subLabel={
            this.props.trackingDetails &&
            this.props.trackingDetails.pudo &&
            this.props.trackingDetails.pudo.subLabel
          }
          fontFamily={this.props.fontFamily}
          fontSize={this.props.fontSize}
        />
      </div>
    );
  }

  showFestiveTheme = () => {
    return (
      <>
        <div className="ci-container-bg-img newCiBackground">
          <img src={this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO
            && !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO.skin) &&
            this.props.trackingDetails.customerInteractionThemeSettingsDTO.skin == "Winter" && !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO.skinBackgroundImageUrl) ?
            this.props.trackingDetails.customerInteractionThemeSettingsDTO.skinBackgroundImageUrl : ''} />
        </div>
        {this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO && !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO.skin) &&
          this.props.trackingDetails.customerInteractionThemeSettingsDTO.skin == "Winter" && !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO.enableSnowfallEffect) && this.props.trackingDetails.customerInteractionThemeSettingsDTO.enableSnowfallEffect == "Yes" ?
          <div className="ci-v3-desktop-animation-">
            <Player
              autoplay
              loop
              src="https://assets6.lottiefiles.com/packages/lf20_by7epsey.json"
              style={{ height: '600px', width: '960px' }}
            >
              <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
            </Player>
          </div> : ''}
      </>
    )
  }


  renderPudoStoreInfo = () => {
    if (this.props.trackingDetails && this.props.trackingDetails.screen != "feedbackScreen"
      && this.props.trackingDetails.storeInfo && !isEmpty(this.props.trackingDetails.storeInfo)) {
      return (
        <>
          <div className="ci-pudo-order-details-desktop">
            <PudoStoreDetails
              trackingDetails={this.props.trackingDetails}
              eta={this.props.updateEta}
              url={this.props.url}
              isJobRemoved={this.props.isJobRemoved}
              isReturn={this.props.isReturn}
              tripEta={this.props.tripEta}
              showDirection={true}
              showContact={true}
            />
          </div>
          <div className="border-bottom-2 "></div>
        </>
      )
    }
  }


  getEta = () => {
    return "Expected arrival on monday";
  }
  renderAttributeList = () => {

    let attributeList = [
      {
        "value": "Trip-22879-1709814111968",
        "sequence": 1,
        "key": "order_ref_num",
        "label": "Order Number"
      },
      {
        "value": "Raghav19",
        "sequence": 2,
        "key": "CN",
        "label": "Name"
      },
      {
        "value": "sdfsdf",
        "sequence": 3,
        "key": "Address",
        "label": "Address"
      }
    ];
    // will sort here based on sequnce 
    let rendredAttributeList = attributeList.map((attribute) => {


      return (
        <div className="address-detail bg-white">
          <Grid container>
            <Grid item xs={12}>
              {
                <div className="flex justify-between align-top">
                  <p className="fs12 text-gray ci-add-label">
                    {attribute.label}
                  </p>
                  <p className="fs14 pt5 align-right">{attribute.value}</p></div>}
            </Grid>
          </Grid>
        </div>
      );
    })
    return rendredAttributeList;
  }

  getShipmentData = (url) => {
    if (url) {
      const start = url.indexOf("tracking?") + "tracking?".length;
      const end = url.indexOf("#/");
      url = url.substring(start, end);
    }
    this.props.actions.getTrackingDetails(url, this.props.isReturn, this.props.trackingDetails, true);
  }

  renderDeskTopView = () => {
    return (
      <React.Fragment>
        <div className="ci-top">
          <div className="ci-background-grey newCiBackground">
            <div className="desktopView ci-desktopView ">
              <Grid container className=" pt24 bg-white p15 position-rtv " style={{ cssText: 'padding-top: 24px !important' }}>
                {this.showFestiveTheme()}
                <Grid className="" item xs={7} style={{
                  zIndex: "2", maxHeight: "537px",maxWidth:'514px',
                  // border: "1px solid #CBD3DF",
                  borderRadius: "8px 0px 0px 8px", paddingLeft: "0px"
                }}>
                  <div className="width-80">
                    <div className="desktopDeliverySec hi" >
                      
                      <div className="shipmentList">
                        {<ShipmentList
                          containerConstants={containerConstants}
                          orderData={this.props.trackingDetails.orderLevelLayoutScreenDTO}
                          getShipmentData={this.getShipmentData}
                          theme={this.props.trackingDetails.customerInteractionThemeSettingsDTO}
                          isNewLayout={this.props.trackingDetails.newLayout}
                        />}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={5} style={{ zIndex: "2" }}>
                  {this.props.trackingDetails.orderLevelLayoutScreenDTO?.attributeList && this.props.trackingDetails.orderLevelLayoutScreenDTO?.attributeList.length > 0 && <div className="width-100 ml15 " style={{ marginLeft: "24px", marginBottom: "24px" }}>

                    <div className=" hi orderLevel bg-white" style={{ marginTop: "0px" }}>
                      {<OrderDetails
                        trackingDetails={this.props.trackingDetails}
                      />}
                    </div>
                  </div>}
                  <div style={{ width: "100%", marginLeft: "24px" }}>
                    {this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO
                      && this.props.trackingDetails.customerInteractionThemeSettingsDTO.quickAction && this.hasQuickAction() && <p className="orderQuickAction" style={{ color: "#647788" }}> QUICK ACTION</p>}
                    {
                      this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO
                      && this.props.trackingDetails.customerInteractionThemeSettingsDTO.quickAction && this.hasQuickAction() &&
                      this.getDesktopQuickAction()
                    }
                  </div>
                </Grid>


              </Grid>
              {this.renderBannerAndCarsoul()}
            </div>
          </div>
          {this.renderSocialMedia()}
        </div>
      </React.Fragment>
    );
  };

  togglePopup() {
    // alert("Hello")
    this.setState({
      feedPopup: !this.state.feedPopup
    });
  }
  checkLandingPage = () => {
    if (this.props.trackingDetails && this.props.trackingDetails.processUpdateForm) {
      let processUpdateForm = JSON.parse(this.props.trackingDetails.processUpdateForm);
      if (processUpdateForm.formList) {
        const formList = processUpdateForm.formList;
        for (let i = 0; i < formList.length; i++) {
          if (formList[i].landingPage && formList[i].landingPage == true) {
            return (

              this.props.openProcessForm(i)
            );
          }
        }
      }
    }
    return (
      this.state.isEmbed == false ?
        <div>
          {this.renderDeskTopView()}
          {this.state.message && this.renderFEMessageDialoge()}
          {this.getSendMsgResponse()}
        </div> :
        <div>
          {this.renderLiveTracking()}
        </div>


    );

  }
  render() {

    return (
      <React.Fragment>
        {this.checkLandingPage()}
      </React.Fragment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...CustomerInteractionAction, ...LivetrackingAction, getPudoData },
      dispatch
    ),
  };
}

function mapStateToProps(state) {
  return {
    trackingDetails: state.customerInteractionReducer.trackingDetails,
    url: state.customerInteractionReducer.url,
    tripEta: state.customerInteractionReducer.tripEta,
    updateEta: state.customerInteractionReducer.updateEta,
    isJobRemoved: state.customerInteractionReducer.isJobRemoved,
    isFeedbackInfoFetch: state.customerInteractionReducer.isFeedbackInfoFetch,
    feedbackInfo: state.customerInteractionReducer.feedbackInfo,
    pudoPoints: state.pudoReducer.pudoPoints,
    previousJobCount: state.customerInteractionReducer.previousJobCount,
    previousJobsMessage: state.customerInteractionReducer.previousJobsMessage,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DesktopView);