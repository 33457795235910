import React from "react";
import Grid from "@material-ui/core/Grid";
import { isEmpty } from "loadsh";
import CallIcon from "../../Utils/icons/CallIcon"
import ChatIcon from "../../Utils/icons/ChatIcon"
import { commonUtilities } from "../../services/commonUtilities";
import { getBorderValue } from "../../Utils/themeConfig";

class FieldExecutiveDetail extends React.Component {
  
  constructor(props){
    super(props);
    this.card=props.newCiData?.driver?.card?.default ? props.newCiData?.defaultSettings?.card:props.newCiData?.driver?.card;
  }
  componentDidMount(){
    var fieldExDetails = document.getElementById('feDetails')
    if (fieldExDetails && this.props.preview) {
      const buttonForEdit = commonUtilities.createButtonElement();
      fieldExDetails.classList.add("borderHover");
      fieldExDetails.insertBefore(buttonForEdit, fieldExDetails.firstChild);
      buttonForEdit.addEventListener("click", () => {
        window.parent.postMessage("fieldExDetails", "*");
      })
    }
  }
  getFeildExecutive = () => {
    let avtarIcon = this.props.trackingDetails.customerInteractionThemeSettingsDTO?.driverAvatarIcon;
    let messageIcon = this.props.trackingDetails.customerInteractionThemeSettingsDTO?.deliveryInstructionsIcon;
    let border = getBorderValue(this.card?.borderColour||'');
    return (
      <div className={"bg-white fe-details "+(this.props.isMobileView?"feMobileDiy":"")} id="feDetails"
        style={{
          backgroundColor:this.card?.cardBgcolour,
          borderRadius:this.card?.cornerRedius?`0px 0px ${this.card?.cornerRedius}px ${this.card?.cornerRedius}px`:'',
          borderRight:border[1],
          borderBottom:border[2],
          borderLeft:border[3],
        }}
       >
        {this.props.trackingDetails && this.props.trackingDetails.userName ? (
          <div>
            <Grid container >
              <Grid item xs={12}>
                <p className="fe-details-label" 
                style={{
                  fontWeight:this.props.newCiData?.driver?.heading?.fontWeight , 
                  color:this.props.newCiData?.driver?.heading?.fontColour,
                }}
                  
                  >
                  {this.props.isReturn ? containerConstants.formatString(containerConstants.YourPackageWillBePickedBy): 
                  this.props?.trackingDetails?.executiveMessage ?  this.props.trackingDetails.executiveMessage :containerConstants.formatString(containerConstants.YourPackageWillBeDeliveredBy)
                  }
                </p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={8} container direction="row" alignItems="center">
                {this.props.trackingDetails.feImage &&
                  !isEmpty(this.props.trackingDetails.feImage) ? (
                  <img
                    src={this.props.trackingDetails.feImage}
                    height="36px"
                    width="36px"
                    style={{borderRadius:"50%",
                      fontFamily: this.props.newCiData?.defaultSettings?.typeface?.fontType,
                    }}
                  />
                ) : this.props.trackingDetails.customerInteractionThemeSettingsDTO && !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO.skin) && 
                this.props.trackingDetails.customerInteractionThemeSettingsDTO.skin=="Winter" && this.props.trackingDetails.customerInteractionThemeSettingsDTO.driverImage && this.props.trackingDetails.customerInteractionThemeSettingsDTO.driverImage=="Yes"?
                (
                  <img 
                    src={require("../../images/santaFE.png")} 
                    height="36px"
                    width="36px"
                  />  
                )
                :
                (
                  <img 
                    src={avtarIcon||require("../../images/user.svg")}
                    height="36px"
                    width="36px"
                    style={{borderRadius:'100%'}}
                  />
                )}
                <span
                  className="layoutPhone"
                >
                  <p
                   style={{
                    fontWeight:this.props.newCiData?.driver?.name?.fontWeight , 
                    color:this.props.newCiData?.driver?.name?.fontColour}}
                  >{this.props.trackingDetails.userName && this.props.trackingDetails.userName!="none"
                    ? this.props.trackingDetails.userName
                    : ""}
                  </p>
                  <p className="feMob"
                    style={{
                      fontWeight:this.props.newCiData?.driver?.contactNumber?.fontWeight , 
                      color:this.props.newCiData?.driver?.contactNumber?.fontColour,
                    }}
                  >
                    {this.props.trackingDetails.callToFE && this.props.trackingDetails.feMob
                      ? this.props.trackingDetails.feMob
                      : ""}
                  </p>
                </span>
              </Grid>
              <Grid item xs={4} >
                <div className="fe-chats">
                  {this.props.trackingDetails.messageToFE &&
                    this.props.trackingDetails.orderId && (
                        messageIcon?<img id="messageToFe" className="fe-chat-circle2" onClick={this.props.setMessageModal} src={messageIcon}/>:
                      <div
                        className="fe-chat-circle"
                        id="messageToFe" 
                        onClick={this.props.setMessageModal}
                        style={{
                          backgroundColor: this.props.secondaryBgColor,
                          borderColor: this.props.secondaryBgColor,
                        }}
                      >
                          <ChatIcon fill={this.props.primaryBgColor} />
                </div>   
                  )
                }
                  { this.callToExecutiveByType() }
                </div>
              </Grid>
            </Grid>
          </div>
        ) : null}
      </div>
    );
  };

  callToExecutiveByType = () => {
    let callIcon = this.props.trackingDetails.customerInteractionThemeSettingsDTO?.callIcon;
    let enableCalling = this.props.trackingDetails.cloudTelephony || this.props.trackingDetails.callToFE;
    let callee = undefined;
    if(this.props.trackingDetails.cloudTelephony) {
      enableCalling = true;
      callee = this.props.trackingDetails.cloudTelephonyUrl;
    } else if(this.props.trackingDetails.callToFE && this.props.trackingDetails.feMob) {
      enableCalling = true;
      callee = this.props.trackingDetails.feMob;
    }

    if(this.props.isMobileView && enableCalling) {
      return (
        callIcon ? 
          <a id="callButtonMobile" href={"tel:" + callee}>
            <img className="fe-chat-circle2" src={callIcon}/>
          </a>:
        <div
          className="fe-chat-circle"
          style={{
            backgroundColor: this.props.secondaryBgColor,
            borderColor: this.props.secondaryBgColor,
          }}
        >
          <a id="callButtonMobile" href={"tel:" + callee}>
            <CallIcon fill={this.props.primaryBgColor} /> 
          </a>
        </div>
      );
    }
  }

  render() {
    return this.getFeildExecutive();
  }
}

export default FieldExecutiveDetail;
