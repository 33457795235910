import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isEmpty } from "loadsh";
import * as CustomerInteractionAction from "../../CustomerInteractionActions";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "./style.css";
import FeedbackScreenV3 from "./FeedbackScreenV3";
import { StarOutline, StarFilled } from "../FeedbackV3/Icons";
import CONSTANTS from "../../Utils/Constants";
import { processUpdateFormService } from "../../services/ProcessUpdateFormService";
import { commonUtilities } from "../../services/commonUtilities";
import { getBorderValue } from "../../Utils/themeConfig";

const ColorButton = withStyles((theme) => ({
  root: {
    color: "white",
    width: "100%",
    height: 45,
    backgroundColor: "#18BF9A",
    "&:hover": {
      backgroundColor: "#18BF9A",
    },
  },
}))(Button);


class FeedbackV3 extends React.Component {
  containerConstants = window.containerConstants;

  constructor(props) {
    super(props);
    this.state = {
      ratings: "",
      update: false,
      showFeebackOptions: false
    }
    // this.setHelpModel = this.setHelpModel.bind(this);
  }

  monthNames = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

   componentDidMount(){
    var feedbackSetting = document.getElementById('feedbackDiy')
    if (feedbackSetting && this.props.preview) {
      const buttonForEdit = commonUtilities.createButtonElement();
      feedbackSetting.classList.add("borderHover");
      feedbackSetting.insertBefore(buttonForEdit, feedbackSetting.firstChild);
      buttonForEdit.addEventListener("click", () => {
        window.parent.postMessage("feedbackSetting", "*");
      })
    }
   }
  setRating = (event) => {
    this.setState({ ratings: event.target.value, showFeebackOptions: !this.state.showFeebackOptions });
  };

  checkMultipleFeedbackAllowed = (feedback) => {
    if (feedback && feedback.multipleFeedbackSubmissionKey) {
      if (feedback.multipleFeedbackSubmissionKey == 'Yes' || feedback.multipleFeedbackSubmissionKey == 'yes')
        return true;
    }
    return false;
  }

  getRatingValue = (value) => {
    let checked = false;
    if (this.props.feedbackInfo && this.props.feedbackInfo.ratingValue) {
      checked = value == this.props.feedbackInfo.ratingValue ? true : false;
    } else {
      checked = value === this.state.ratings ? true : false;
    }
    return checked;
  };

  validation = () => {
    if (!isEmpty(this.state.ratings)) {
      return false;
    }
    return true;
  };
  updateSelectedLocation = async () => {
    let message = "Nps Rating By Customer - ".concat(this.state.rating);
    let res = await this.props.actions.updateLocation(
      this.props.url,
      null,
      null,
      message
    );
    this.setState({ update: true });
  };


  getEta = (feedback,deliveryDate) => {
    var timeFormat = processUpdateFormService.getTimeFormat(this.props.trackingDetails);
    if (deliveryDate && !isEmpty(deliveryDate)) {
      let eta;
      if (this.props.trackingDetails.browserTimeZone && this.props.trackingDetails.browserTimeZone == true && !feedback.showStatusFromTrackerTimeLine) {
        eta = new Date(deliveryDate.replace(/-/g, "/") + " UTC");
      }
      else {
        eta = new Date(deliveryDate.replace(/-/g, "/"));
      }
      var hours = eta.getHours(); // gives the value in 24 hours format
      var minutes = eta.getMinutes() < 10 ? '0' + eta.getMinutes() : eta.getMinutes();
      if (timeFormat == CONSTANTS.TWENTYFOUR_HOUR_FORMAT) {
        var finalTime = eta.getDate() + " " + this.monthNames[eta.getMonth()] + ", " + hours + ":" + minutes;
        return finalTime;
      }
      var AmOrPm = hours >= 12 ? 'PM' : 'AM';
      hours = (hours % 12) || 12;
      // hours = hours < 10 ? '0'+hours : hours;
      var finalTime = eta.getDate() + " " + this.monthNames[eta.getMonth()] + ", " + hours + ":" + minutes + " " + AmOrPm;
      return finalTime
    }

  }

  backScreen = (value) => {
    this.setState({ showFeebackOptions: !this.state.showFeebackOptions, ratings: value });
    // document.body.style.overflowY = "auto";
    this.props.closeRattingScreenDrawer();
  };

  renderFeedbackSubText = (feedback,statusCategoryTobeShown) => {
    if (feedback.showStatusFromTrackerTimeLine) {
    if (isEmpty(statusCategoryTobeShown) || statusCategoryTobeShown == 3) {
      return (feedback.successDeliveryDescription && !isEmpty(feedback.successDeliveryDescription)) ? feedback.successDeliveryDescription : "We have successfully delivered your order";
    } else if (statusCategoryTobeShown == 4) {
      return (feedback.failDeliveryDescription && !isEmpty(feedback.failDeliveryDescription)) ? feedback.failDeliveryDescription : "We were not able to deliver your order, sorry for inconvenience";
    }
    }
    if(this.props.trackingDetails.orderStatus == 3 ){
      return (feedback.successDeliveryDescription && !isEmpty(feedback.successDeliveryDescription))?feedback.successDeliveryDescription:"We have successfully delivered your order";
    } else if(this.props.trackingDetails.orderStatus == 4 ){
      return (feedback.failDeliveryDescription && !isEmpty(feedback.failDeliveryDescription))?feedback.failDeliveryDescription:"We were not able to deliver your order, sorry for inconvenience";
    }
  }
  renderOrderStatusHeding = () => {
    var responseSortedStatusList = commonUtilities.getSortedStatusLogOnDateTime(this.props.trackingDetails,true);
    var statusList = responseSortedStatusList && responseSortedStatusList["statusLogList"] ? responseSortedStatusList["statusLogList"] : [];
    var totalStatus = statusList.length;
    var statusToBeShown = "";
    var statusCategoryTobeShown = "";
    var statusTime = "";
    statusList.map((status, outerIndex) => {
      status.status.map((singleStatus, index) => {
        const configuredStatus = commonUtilities.typeOfStatus(singleStatus, this.props.trackingDetails)
        if (!(!isEmpty(configuredStatus) && (configuredStatus.mileStone == CONSTANTS.HIDDEN))) {
          statusCategoryTobeShown = singleStatus.statusCategory == "success" ? 3 : 4;
          statusToBeShown = isEmpty(configuredStatus.statusName) ? singleStatus.status : configuredStatus.statusName;
          statusTime = status.previousDate + status.timings[index];
        }
      })
    })
    return [statusToBeShown,statusCategoryTobeShown,statusTime];
  }
  renderOrderStatus = (feedback) => {
    let className =  "date fs14 " + this.props.newCiData ? "" :"comm+on-grey";
    if (feedback.showStatusFromTrackerTimeLine) {
      var statusCategoryAndResponse = this.renderOrderStatusHeding();
      var statusToBeShown = statusCategoryAndResponse && (statusCategoryAndResponse[0]) ? statusCategoryAndResponse[0] : "";
      var statusCategoryTobeShown = statusCategoryAndResponse && (statusCategoryAndResponse[1]) ? statusCategoryAndResponse[1] : "";
      var statusTime = statusCategoryAndResponse && (statusCategoryAndResponse[2]) ? statusCategoryAndResponse[2] : "";
      return (
        <div className="order_status_box">
          <i class={statusCategoryTobeShown == 3 ? "bi bi-check-circle-fill mr-2" : "bi bi-exclamation-circle-fill mr-2"} style={{ color: statusCategoryTobeShown == 3 ? this.props.newCiData?.orderDeliver?.orderDelivered?.textColour : '#F1C21B' ,
              fontFamily:this.props.newCiData?.defaultSettings?.typeface?.fontType ,
          }}></i>
          <div className="width-100 ">
            <div className="status_box flex justify-between align-center ">
              <h2 className="m0" style={{ color: statusCategoryTobeShown == 3 ? this.props.newCiData?.orderDeliver?.orderDelivered?.textColour : '#F1C21B' ,
                   fontFamily:this.props.newCiData?.defaultSettings?.typeface?.fontType ,
              }}>{statusToBeShown}</h2>
              <div className={className} style={{color:this.props.newCiData?.orderDeliver?.deliveryDateTime?.textColour, fontWeight:this.props.newCiData?.orderDeliver?.deliveryDateTime?.fontWeight, fontFamily:this.props.newCiData?.defaultSettings?.typeface?.fontType,
}}>{this.getEta(feedback,statusTime)}</div>
            </div>
            <div className="ci-feedback-desc-p" style={{color:this.props.newCiData?.orderDeliver?.description?.textColour, fontWeight:this.props.newCiData?.orderDeliver?.description?.fontWeight, fontFamily:this.props.newCiData?.defaultSettings?.typeface?.fontType}}>{this.renderFeedbackSubText(feedback, statusCategoryTobeShown)}</div>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className="order_status_box">
          <i class={this.props.trackingDetails.orderStatus == 3 ? "bi bi-check-circle-fill mr-2" : "bi bi-exclamation-circle-fill mr-2"} style={{ color: this.props.trackingDetails.orderStatus == 3 ? this.props.newCiData?.orderDeliver?.orderDelivered?.textColour : '#F1C21B',
           fontFamily:this.props.newCiData?.defaultSettings?.typeface?.fontType ,
           }}></i>
          <div className="width-100 ">
            <div className="status_box flex justify-between align-center ">
              <h2 className="m0" style={{ color: this.props.trackingDetails.orderStatus == 3 ? this.props.newCiData?.orderDeliver?.orderDelivered?.textColour : '#F1C21B',fontFamily:this.props.newCiData?.defaultSettings?.typeface?.fontType}}>{this.props.trackingDetails.status}</h2>
              <div className={className} style={{color:this.props.newCiData?.orderDeliver?.deliveryDateTime?.textColour, fontWeight:this.props.newCiData?.orderDeliver?.deliveryDateTime?.fontWeight,fontFamily:this.props.newCiData?.defaultSettings?.typeface?.fontType}}>{this.getEta(feedback,feedback.attemptedDate)}</div>
            </div>
            <div className="ci-feedback-desc-p" style={{color:this.props.newCiData?.orderDeliver?.description?.textColour, fontWeight:this.props.newCiData?.orderDeliver?.description?.fontWeight,fontFamily:this.props.newCiData?.defaultSettings?.typeface?.fontType}}>{this.renderFeedbackSubText(feedback)}</div>
          </div>
        </div>
      )
    }
  }

  renderFeedbackHeading = (feedback) => {
    if (this.props.feedbackInfo && !isEmpty(this.props.feedbackInfo)) {
      return (
        <div
          className="rate-your-experience"
            style={{
              color:this.props.newCiData?.deliverRating?.heading?.fontColour,
              fontWeight:this.props.newCiData?.deliverRating?.heading?.fontWeight,
              fontFamily:this.props.newCiData?.defaultSettings?.typeface?.fontType
            }}
          >
          { containerConstants.formatString(
            containerConstants.ThankYouForYourValuableFeedback
          )}
        </div>
      )
    }
    return (
      <div
        className="rate-your-experience"
        style={{
          color:this.props.newCiData?.deliverRating?.heading?.fontColour,
          fontWeight:this.props.newCiData?.deliverRating?.heading?.fontWeight,
          fontFamily:this.props.newCiData?.defaultSettings?.typeface?.fontType
        }}
        >
        {feedback && feedback.feedbackHeading && !isEmpty(feedback.feedbackHeading) ? feedback.feedbackHeading :
          this.props.newCiData?.deliverRating?.deliverRating?.label || containerConstants.formatString(containerConstants.RateYourExperience)}
      </div>
    )
  }

  getRatingClass = () => {
    const rate = this.props.feedbackInfo.ratingValue;
    if (rate) {
      if (rate == 1) {
        return "star-rating-1"
      }
      else
        if (rate == 2) {
          return "star-rating-2"
        }
        else
          if (rate == 3) {
            return "star-rating-3"
          }
          else
            if (rate == 4) {
              return "star-rating-4"
            }
            else
              if (rate == 5) {
                return "star-rating-5"
              }
    }
    return "star-rating";
  }

  renderStarRating = (feedback) => {
    return (
      <div className="flex justify-center"
      >
        <div
          className={this.getRatingClass()}
          style={
            feedback["podImage"] && !isEmpty(feedback["podImage"])
              ? { fontSize: this.props.fontSize, marginTop: "10px" }
              : { fontSize: this.props.fontSize, padding: "10px" }
          }
        >
          <input
            type="radio"
            id="1-stars"
            name="rating"
            value="5"
            checked={this.getRatingValue("5")}
            onClick={this.checkMultipleFeedbackAllowed(feedback) ? this.setRating : isEmpty(this.props.feedbackInfo) ? this.setRating : null}
            disabled={this.checkMultipleFeedbackAllowed(feedback) ? false : !isEmpty(this.props.feedbackInfo)}
          />
          <label htmlFor="1-stars" className="star">
            <StarOutline />
            <StarFilled />
          </label>
          <input
            type="radio"
            id="2-stars"
            name="rating"
            value="4"
            checked={this.getRatingValue("4")}
            onClick={this.checkMultipleFeedbackAllowed(feedback) ? this.setRating : isEmpty(this.props.feedbackInfo) ? this.setRating : null}
            disabled={this.checkMultipleFeedbackAllowed(feedback) ? false : !isEmpty(this.props.feedbackInfo)}
          />
          <label htmlFor="2-stars" className="star">
            <StarOutline />
            <StarFilled />
          </label>
          <input
            type="radio"
            id="3-stars"
            name="rating"
            value="3"
            checked={this.getRatingValue("3")}
            onClick={this.checkMultipleFeedbackAllowed(feedback) ? this.setRating : isEmpty(this.props.feedbackInfo) ? this.setRating : null}
            disabled={this.checkMultipleFeedbackAllowed(feedback) ? false : !isEmpty(this.props.feedbackInfo)}
          />
          <label htmlFor="3-stars" className="star">
            <StarOutline />
            <StarFilled />
          </label>
          <input
            type="radio"
            id="4-stars"
            name="rating"
            value="2"
            checked={this.getRatingValue("2")}
            onClick={this.checkMultipleFeedbackAllowed(feedback) ? this.setRating : isEmpty(this.props.feedbackInfo) ? this.setRating : null}
            disabled={this.checkMultipleFeedbackAllowed(feedback) ? false : !isEmpty(this.props.feedbackInfo)}
          />
          <label htmlFor="4-stars" className="star">
            <StarOutline />
            <StarFilled />
          </label>
          <input
            type="radio"
            id="5-star"
            name="rating"
            value="1"
            checked={this.getRatingValue("1")}
            onClick={this.checkMultipleFeedbackAllowed(feedback) ? this.setRating : isEmpty(this.props.feedbackInfo) ? this.setRating : null}
            disabled={this.checkMultipleFeedbackAllowed(feedback) ? false : !isEmpty(this.props.feedbackInfo)}
          />
          <label htmlFor="5-star" className="star">
            <StarOutline />
            <StarFilled />
          </label>
        </div>
      </div>
    )
  }

  getRatingDiv = () => {
    if(this.props.trackingDetails && !isEmpty(this.props.trackingDetails.feedback)){
    let feedback = JSON.parse(this.props.trackingDetails.feedback);
    if ((feedback && feedback.disableFeedbackRating) || this.state.showFeebackOptions) {
      return (
        <div className="rating-div feedbackScreen rating-box">
          {this.renderOrderStatus(feedback)}
        </div>
      );
    }
    return (
      <div className="rating-div feedbackScreen rating-box">
        {this.renderOrderStatus(feedback)}
        {(!this.state.ratings || (this.props.feedbackInfo && !isEmpty(this.props.feedbackInfo) && ((this.state.ratings == this.props.feedbackInfo.ratingValue)))) ? this.renderFeedbackHeading(feedback) : null}
        {(!this.state.ratings || (this.props.feedbackInfo && !isEmpty(this.props.feedbackInfo) && ((this.state.ratings == this.props.feedbackInfo.ratingValue)))) ? this.renderStarRating(feedback) : null}
      </div>
    );}
    else{
      console.log("feedback node not present either because the status is not mapped or the transaction is not made for the current job, try enabling 3PL in feedback config")
    }
  };

  render() {
    let card = this.props.newCiData?.deliverRating?.card?.default ? this.props.newCiData?.defaultSettings?.card:this.props.newCiData?.deliverRating?.card;
    let border = getBorderValue(card?.borderColour||'')
    return (<div id ="feedbackDivForDiy" className="bg-white"
      style={{
            backgroundColor:card?.cardBgcolour,
            borderRadius:`${card?.cornerRedius||0}px`,
            borderTop:border[0],
            borderRight:border[1],
            borderBottom:border[2],
            borderLeft:border[3],
            fontFamily:this.props.newCiData?.defaultSettings?.typeface?.fontType,
      }}
    >
      {this.getRatingDiv()} 
      {
        this.state.ratings && !isEmpty(this.state.ratings) && this.state.showFeebackOptions &&
        <div className="feedback-custom feedback-custom-web-view">
          <FeedbackScreenV3
            isMobile={this.props.trackingDetails.isMobileType}
            ratings={this.state.ratings}
            showFeebackOptions={this.state.showFeebackOptions}
            backScreen={this.backScreen}
            fontSize={this.props.fontSize}
            fontFamily={this.props.fontFamily}
            primaryBgColor={this.props.primaryBgColor}
            primaryTextColor={this.props.primaryTextColor}
            secondaryBgColor={this.props.secondaryBgColor}
            secondaryTextColor={this.props.secondaryTextColor}
            feedbackInfo={this.props.feedbackInfo}
            type={this.props.type}
            isReturn={this.props.isReturn}
            newCiData ={this.props.newCiData}
          />
        </div>

      }

    </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    trackingDetails: state.customerInteractionReducer.trackingDetails,
    url: state.customerInteractionReducer.url,
    feedback: state.customerInteractionReducer.feedback,
    isFeedbackInfoFetch: state.customerInteractionReducer.isFeedbackInfoFetch,
    feedbackInfo: state.customerInteractionReducer.feedbackInfo
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...CustomerInteractionAction }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackV3);

