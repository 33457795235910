
import { createGlobalStyle } from 'styled-components';

import { containerConstantsService } from './Utils/containerConstants/index';
const languageDirection = window.sessionStorage.getItem('languageDirection');
var isEmbed = window.location.href.indexOf('&embed=true') != -1;
const cssjanus = require('cssjanus');
const ltr = `
.miller-knoll {
  .mb24{
    margin-bottom:24px !important;
  }

  .orderInfo .ci-order-label-title {
    font-weight: bold;
    color: #333333;
    line-height: 30px;
  }

  .widget3 {
    // background: #fff;
    margin-right:24px;
    margin-bottom:24px;
    header {
      margin-bottom: 0;
      padding: 14px 24px;
      font-weight: bold;
      font-size: 16px;
      border-bottom: 1px solid #d3d3d3;
      
    }
  }

  .widget1 {
    padding: 0 !important;
    .return-instruction-box{
      box-shadow:none;
      margin:0 0 24px 0;
      padding-top:0px;
      border-radius:0px;
      border:5px solid white;
    }
  }
  .widget1-header {
    margin-top: 0 !important;
    padding:0 24px 15px 24px !important;
  }


  .widget7{
    margin-bottom:24px;
    padding:14px 24px;
    background: #fff;
    .vertical-timeline-element--work{

    .vertical-timeline-element-content{
        .vertical-timeline-element-content-arrow{
          border-color:transparent;
        }
      }
    }
  }

  .widget4{
    margin-right:24px;
  }


  &.desktop-view-market-asset{
    padding:0px;
  }


  .desktop-view-market-box-space {
    padding:0px !important;

    .desktop {
      height:292px !important;
    }

  }
  
  .desktop-view-market-asset {
    padding:0px !important;
  }

  .box {
    padding: 0 24px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      padding-bottom: 16px !important;
    }
  }

  .img-wrapper {
    padding: 0 !important;

    hr {
      border: none;
      border-bottom: 1px solid #d3d3d3;
    }
  }

  .banner-image-image {
    border-radius: 0 !important;

  }

  .fe-details, #map {
    // border-radius: 0 !important;

  }
  #map{
    height:273px !important;
  }
  .pod-img{
    height:360px;
    padding:0px !important;
  }
  .feedback_carousel{
    margin-bottom:27px;
  }
  .shipmentList{
    margin-bottom:24px !important;
     ul{
       padding-bottom:0px;
     }
  }
  .shipmentList ul li{
   margin:0px !important;
  }
  .shipmentList .shipment{
    border-radius:0 !important;
    margin-bottom:1px;
  }
  .orderLevel{
    border-radius:0px !important;
  }
  .imgaeArray{
    padding:15px 24px;
  }
  .shipmentList{
    ::-webkit-scrollbar {
      width: 5px;
       background-color: #F5F5F5;
       
    }
    ::-webkit-scrollbar-thumb {
      background-color: #e3e1e1;
    }
  }
  .ci-tracking-map-conatiner{
    padding:0px;
  }
  .fullheight{
    min-height:100vh;
  }
}

@media only screen and (max-width: 768px) {
  .miller-knoll {  
    .widget3{
      margin:13px 0px 0px 0px;
    }
    .widget6{
    margin-top:13px;
    }
    .widget8{
      margin-top:13px;
    }
    hr.seprator {
      height: 12px;
      background: trasparent;
      border: none;
      margin: 0;
    }
  
    .orderInfo {
      margin-bottom: 0; 

      .box {
        padding: 0px 0px !important;
      }
    }

    .header-eta {
      line-height: 35px;
      margin-bottom: 15px;
    }

    .widget4  {
      margin: 0 !important;
    }

    .statusHeader.position-rtv {
      padding-bottom: 0;
    }

    .widget7 {
      padding: 0 !important;
      margin-top:13px;
      margin-bottom:0;
      h3{margin-bottom:0;}

      h3 {
        padding: 14px 24px;
      }

      .box {
        padding: 0 24px !important;
      }

      hr {
        border: none;
        border-bottom: 1px solid #d3d3d3;
      }
    }

    .widget5{
      padding: 0 !important;

      h3 {
        padding: 14px 24px;
      }

      .vertical-timeline {
        width: auto;
        margin: 0 24px;
      }
        .vertical-timeline-element--work{

    .vertical-timeline-element-content{
        .vertical-timeline-element-content-arrow{
          border-color:transparent;
        }
      }
    }
      
    }

    .widget8 {
      .help_screen {
        padding: 10px 24px !important;
      }
      
    }
    .widget1{
      padding-top:0px !important

    }
    .feedback_carousel{
      margin:0px;
    }
    .mobileViewDetails{
      border:none;
      margin:0;
      box-shadow:none;
      border-radius:0px !important;
      padding:16px 24px ;

      .ci-add-label{
        font-weight:700;
        color: #333333;

      }
    }
    .mobileViewOrder{
     padding:0px;
     margin-bottom:12px!important;
    }

    ul{
      padding-bottom:0px !important;
      margin-bottom:12px!important;

     li {
       margin:0 !important;
          &:last-child {
          border:none;
          }
        }
     }
    .shipment{
      border:none;
      border-bottom:1px solid #E8DFD5;
      ;
      border-radius:0 !important;
      box-shadow:none;
      margin:0px;

    }
    .imgaeArray {
      h3{
        padding:0px !important;
      }
      span{
       display:table-header-group;
      }
    }


  }
}



 `;




let modifiedCss = ltr;
if (languageDirection && languageDirection === 'rtl') {
  modifiedCss = cssjanus.transform(ltr);
}


const ClientSpecificStyle = createGlobalStyle`${modifiedCss}`

export default ClientSpecificStyle
