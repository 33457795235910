const portugeseStrings={
YourShipmentHasBeenDelivered:'A sua remessa foi entregue',
DeliveredBy:'Entregue por',
RateYourExperience:'Classifique a sua experiência',
ThankYouForYourValuableFeedback:'Obrigado pelos seus valiosos comentários',
YourFeedbackWillContinuallyHelpUsImproveOurServices:'Os seus comentários vão ajudar-nos a melhorar continuamente os nossos serviços',
DeliveryFailed:'Falha na entrega',
AddDeliveryPreferences:'Adicionar preferência de entrega',
YourDeliveryPreferenceAreSavedSuccessFully:'As suas preferências de entrega foram guardadas com sucesso',
WeAreUnableToTrackYourShipmentRightNow:'Não é possível rastrear a sua remessa neste momento',
PleaseUpdateLocationInsideCircle:'Atualize a localização dentro do círculo',
Ok:'Ok',
Update:'Atualizar',
PickCurrentLocation:'Escolher o local atual',
SearchFor:'Procurar por',
ThisSchedulingLinkHasExpired:'Este link de agendamento expirou',
WeWillShareANewLinkWithYouShortly:'Em breve, iremos partilhar um novo link consigo',
UhHo:'Uh ho!',
NeedHelp:'Precisa de ajuda?',
CallSupport:'Ligar para Suporte',
EmailSupport:'E.mail para Suporte',
DeliveryAt:'Entregue em',
DeliveredAt: 'Delivered At',
ContactNo:'Nº contacto',
TrackOnMap:'Rastrear no map',
MessageToExecutive:'Mensagem para Executivo',
Cancel:'Cancelar',
Send:'Enviar',
Executive:'Executivo',
Comments:'Comentários (opcional)',
SubmitFeedback:'SUBMETER COMENTÁRIO',
TrackingNo:'Nº de rastreio',
Details:'Detalhes',
Characters:'Caracteres',
Updating:'a atualizar…',
OopsSomethingWentWrongPleaseTryAfterSomeTime:'Ups! Ocorreu algo errado, tente novamente após algum tempo',
NotListedAboveSendACustomMessage:'Não listado acima, enviar uma mensagem personalizada',
ChangeDeliveryLocation:'Alterar localização da entrega',
AttemptedBy:'Tentado por',
DeliveredTo:'Entregue a',
SendMessage:'ENVIAR MENSAGEM',
VIEW_DETAILS:'Visualizar detalhes',
SAVE_LOCATION:'GUARDAR LOCALIZAÇÃO',
LOCATION:'localização',
SEARCH_ADDRESS:'Pesquisar endereço',
OpenHours:'Horário de funcionamento',
Note:'Nota',
Address:'Endereço',
Nearest: 'Mais próximo',
ContactNumber:'Número de contacto',
FAQS:'Perguntas Frequentes',
HowManyTimesCanIChangeMyPickUpPoint: 'Quantas vezes posso alterar o meu ponto de recolha?',
YouCanChangeItOnly: 'Pode alterá-lo apenas',
GoBack:'Retroceder',
SetAsPickupStore:'Definir como loja de recolha',
MapView:'Vista do mapa',
SearchForPickupPointNearYou:'Pesquisar por ponto de recolha perto de si',
Arriving:'A chegar',
LiveTrackingWillStart:'Vai começar a monitorização em direto',
DriverTrackerWillGetActivatedOnceTheyAreOnWay:  'O dispositivo de monitorização do motorista vai ser ativado assim que estiver a caminho',
TrackerTimeline:'Cronologia do dispositivo de monitorização',
PM:'Tarde',
AM:'Manhã',
TodayAt:'hoje às',
TomorrowAt:'amanhã às',
Today:'Hoje',
Tomorrow:'Amanhã',
On:'a',
In:'em',
Soon:'em breve',
Min:'min',
Mins:'mins',
Items:'ARTIGOS',
ViewDetails: 'Visualizar detalhes',
DeliveryInformation:'Informações de entrega',
TrackingHistory:'Histórico de monitorização',
StayConnected:'Mantenha-se ligado',
YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'Já tentou fazer um pagamento nos últimos minutos. Deseja cancelar as transações anteriores',
No: 'NÃO',
yes:'SIM',
YourPackageWillBeDeliveredBy : 'O seu pacote vai ser entregue por',
OrderDetails: 'Dados da encomenda',
OrderInformation:'Informações da encomenda',
LastAttempted:'Last Attempted',
DeliveryBy:'Delivery By',
Pickuppointisupdated:'Ponto de coleta é atualizado',
MsgToFePageHeader:'Send Delivery Instructions',
MsgToFeSupportingText:'What do you want to tell the driver?',
AddYourMsgHere:'Add your message here',
InstructionsSentSuccessfully:'Instructions Sent Successfully',
SomethingWentWrongPlsTryAgain:'Something went wrong, please try again.',
times:"times",
TrackMovementInRealTime:"Track Movement in Real-Time",
Hours:"hours",
Hour:"hour",
WriteYourFeedbackOptional:"Escreva seu feedback (opcional)",
ReturnOrder: "Return Order",
TheLinkYouAreTryingToOpenHasExpired: "O link que você está tentando abrir expirou",
SelectAnAvailableTimeSlot:"Select an available time slot",
January:"JANUARY",
February:"FEBRUARY",
March :"MARCH",
April:"APRIL",
May:"MAY",
June:"JUNE",
July:"JULY",
August:"AUGUST",
September:"SEPTEMBER",
October:"OCTOBER",
November:"NOVEMBER",
December:"DECEMBER",
Monday:"MONDAY",
Tuesday:"TUESDAY",
Wednesday:"WEDNESDAY",
Thursday:"THURSDAY",
Friday:"FRIDAY",
Saturday:"SATURDAY",
Sunday:"SUNDAY",
ViewTimings: "View Timings",
HideTimings: "Hide Timings",
PleaseAnswerAllMandatoryQuestions:"Por favor, responda a todas as perguntas obrigatórias",
HelpLineLink: 'Helpline Link',
ShipmentOf: "Remessa%de",
OtherShipments: "Outras Remessas",
OrderInformation:"Order Information",
OR:"ou",
TrackShipment:"Rastreie o envio",
TrackingDescription:"Insira os detalhes abaixo para obter detalhes da sua remessa",
Continue:"Continuar",
InvalidCredentials:"Credenciais inválidas. Por favor, tente novamente"
}
export default portugeseStrings;