
import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Close from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBack';
import MyLocation from '@material-ui/icons/MyLocation';
import LocationOn from '@material-ui/icons/LocationOn';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {processUpdateFormService } from '../../services/ProcessUpdateFormService'
import {isEmpty} from 'loadsh'
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { containerConstantsService } from '../../Utils/containerConstants/index';
//import CrossIcon from '@material-ui/icons/Clear';
import ClearIcon from '@material-ui/icons/Clear';






class LocateAddress extends React.Component {
  containerConstants = window.containerConstants;


  address='';updateAddress='';

  constructor(props){
     super(props);
     this.state ={
        addressList:'',
        loader:false,
        addressSet:false
     }
     this.herePoweredAccount = window.localStorage.getItem('isHerePoweredAccount') === 'true' ? true : false;
     this.fontType = this.props.trackingDetails.newLayout?this.props.trackingDetails.customerInteractionThemeSettingsDTO?.newCiData?.defaultSettings?.typeface?.fontyType:"Roboto";
  }
  getAddress = async(event) =>{
    this.address = event.target.value;
      if(event.keyCode==13){
        try{
          this.setState({'loader':true})
          this.updateAddress = await processUpdateFormService.searchAddress(this.address,this.props.url,this.herePoweredAccount);
          this.setState({'addressList':JSON.parse(this.updateAddress.address),'loader':false})
        }catch(err){
          this.setState({'addressList':this.updateAddress,'loader':false})
        }
      } else {
        this.setState({'addressSet':!this.state.addressSet})
      }
   
  }

 searchAddress = async(event) =>{
  try{
        this.setState({'loader':true})
        this.updateAddress = await processUpdateFormService.searchAddress(this.address,this.props.url,this.herePoweredAccount)
        this.setState({'addressList':JSON.parse(this.updateAddress.address),'loader':false})
      }catch(err){
        this.setState({'loader':false})
      }
  }

   getListItem=()=>{
       const address =[];
       if(!isEmpty(this.address)){
        address.push( 
          <div>
          <ListItem button style={{color: '#4f92e3', padding: 15,fontStyle:'italic'}} onClick={(e)=>this.props.searchLocation(this.state.addressList)} >
              <Typography
                component="span"
                className="fs14"
                
              >
             <p onClick={this.searchAddress}> <img style={{marginRight:'12px'}} height='14px' src={require('../../images/search.png')}/>

             {containerConstants.formatString(containerConstants.SearchFor)} {this.address}</p>
              </Typography>
          </ListItem>
          <Divider /> 
          </div>
       );
       }

       address.push( 
               <div>
               <ListItem button style={{color: '#727272', padding: 15}} onClick={this.props.currentLocation} >
                <ListItemIcon style={{minWidth: 35}}>
                  <MyLocation className="fs18" />
                </ListItemIcon>
                <Typography style={{color:this.props.fontColor,fontFamily:`${this.fontType||"Roboto"},sans-serif`,fontSize:this.props.fontSize}}
                  component="span"
                  className="fs14"
                  
                >
                 {containerConstants.formatString(containerConstants.PickCurrentLocation)}
                </Typography>
            </ListItem>
            <Divider /> 
               </div>
            );
      if(!isEmpty(this.state.addressList.formatted_address)){
        address.push( 
          <div>
          <ListItem button style={{color: '#727272', padding: 15}} onClick={(e)=>this.props.searchLocation(this.state.addressList)} >
              <Typography style={{color:this.props.fontColor,fontFamily:this.props.fontType,fontSize:this.props.fontSize}}
                component="span"
                className="fs14"
                
              >
                {this.state.addressList.formatted_address}
              </Typography>
          </ListItem>
          <Divider /> 
          </div>
       );
  
      }
      return address;
   }

  render() {
    return (
      <div className="bg-white">
          <div className="pt10 pr15 pl0 flex align-center">
            <div className="p10">
              <ArrowBack className="fs24" onClick ={this.props.back} color='#727272'/>
            </div>
            <TextField
              id="standard-name"
              margin="normal"
              value ={this.props.addressValue}
              className="width-100 input-p-15"
              style={{marginTop: 0, marginLeft: 5}}
              autoFocus={true}
              placeholder={containerConstants.formatString(containerConstants.SEARCH_ADDRESS)}
              onKeyDown ={this.getAddress}
              autoComplete='off'
              InputProps={{
                style: { color:this.props.fontColor,fontFamily:`${this.fontType||"Roboto"},sans-serif`},
                endAdornment: <InputAdornment position="start">
                </InputAdornment>,
              }}
              
              
              onChange ={(event)=>{
                this.getAddress(event);
              }}
             
            />
            <div className="p10">
              <ClearIcon className="fs24" onClick ={this.props.back} color='#727272'/>
            </div>
          </div>
          
          {this.state.loader ?
            <div className="text-center mt-20">
            <Fade
                in={this.state.loader}
                style={{
                  transitionDelay: (this.state.loader) ? '800ms' : '0ms',
                }}
                unmountOnExit
              >
                <CircularProgress />
              </Fade>
              </div> :
            <div className="address-suggestion">
            <List>
            {this.getListItem()}
            </List>
          </div>
          }
        </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    url:state.customerInteractionReducer.url,
    trackingDetails: state.customerInteractionReducer.trackingDetails
  };
}

export default connect(mapStateToProps)(LocateAddress);