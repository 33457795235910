const greekStrings = {
    YourShipmentHasBeenDelivered:'Η αποστολή σας παραδόθηκε',
    DeliveredBy:'Παραδόθηκε από',
    RateYourExperience:'Αξιολόγησε την υπηρεσία μας',
    ThankYouForYourValuableFeedback:'Σας ευχαριστούμε για την πολύτιμη γνώμη σας',
    YourFeedbackWillContinuallyHelpUsImproveOurServices:'Η γνώμη σας θα μας βοηθά να βελτιώνουμε συνεχώς τις υπηρεσίες μας',
    DeliveryFailed:'Η παράδοση απέτυχε',
    AddDeliveryPreferences:'Προσθήκη προτιμήσεων παράδοσης',
    YourDeliveryPreferenceAreSavedSuccessFully:'Οι προτιμήσεις σας για την παράδοση αποθηκεύτηκαν με επιτυχία',
    WeAreUnableToTrackYourShipmentRightNow:'Δεν μπορούμε να παρακολουθήσουμε την αποστολή σας αυτήν τη στιγμή',
    PleaseUpdateLocationInsideCircle:'Ενημερώστε την τοποθεσία εντός του κύκλου',
    Ok:'Ok',
    Update:'Ενημέρωση',
    PickCurrentLocation:'Επιλογή τρέχουσας τοποθεσίας',
    SearchFor:'Αναζήτηση για',
    ThisSchedulingLinkHasExpired:'Ο σύνδεσμος προγραμματισμού έληξε',
    WeWillShareANewLinkWithYouShortly:'Θα σας στείλουμε έναν νέο σύνδεσμο σύντομα',
    UhHo:'Ωχ!!',
    NeedHelp:'Δεν θα είσαι στον τόπο παράδοσης;',
    CallSupport:'Τηλέφωνο εξυπηρέτησης πελατών',
    EmailSupport:'email εξυπηρέτησης πελατών',
    DeliveryAt:'Παράδοση στις',
    DeliveredAt: 'Ημερομηνία παράδοσης',
    ContactNo:'Αριθμός επικοινωνίας',
    TrackOnMap:'Παρακολούθηση στον χάρτη',
    MessageToExecutive:'Μήνυμα σε στέλεχος',
    Cancel:'Ακύρωση',
    Send:'Αποστολή',
    Executive:'Στέλεχος',
    Comments:'Σχόλια (προαιρετικά)',
    SubmitFeedback:'Υποβολή αξιολόγησης',
    TrackingNo:'Αριθμός αποστολής',
    Details:'Λεπτομέρειες',
    Characters:'Χαρακτήρες',
    Updating:'ενημέρωση……',
    OopsSomethingWentWrongPleaseTryAfterSomeTime:'Ωχ! Κάτι πήγε στραβά, δοκιμάστε μετά από λίγη ώρα',
    NotListedAboveSendACustomMessage:'Δεν παρατίθεται παραπάνω, στείλτε ένα προσαρμοσμένο μήνυμα',
    ChangeDeliveryLocation:'Αλλαγή τοποθεσίας παράδοσης',
    AttemptedBy:'Η παράδοση έγινε από',
    DeliveredTo:'Παραλήφθηκε από',
    SendMessage:'ΑΠΟΣΤΟΛΗ ΜΗΝΥΜΑΤΟΣ',
    VIEW_DETAILS:'Προβολή λεπτομερειών',
    SAVE_LOCATION:'ΑΠΟΘΗΚΕΥΣΗ ΤΟΠΟΘΕΣΙΑΣ',
    LOCATION:'τοποθεσία',
    SEARCH_ADDRESS:'Αναζήτηση διεύθυνσης',
    OpenHours:'Ανοιχτές ώρες',
    Note:'Σημείωση',
    Address:'Διεύθυνση',
    Nearest: 'Πλησιέστερο',
    ContactNumber:'Αριθμός επικοινωνίας',
    FAQS:'Συχνές ερωτήσεις',
    HowManyTimesCanIChangeMyPickUpPoint: 'Πόσες φορές μπορώ να αλλάξω το σημείο παραλαβής μου;',
    YouCanChangeItOnly: 'Μπορείτε να το αλλάξετε μόνο',
    GoBack:'Επιστροφή',
    SetAsPickupStore:'Ορισμός ως καταστήματος παραλαβής',
    MapView:'Προβολή χάρτη',
    SearchForPickupPointNearYou:'Αναζήτηση για σημείο παραλαβής κοντά σας',
    Arriving:'Άφιξη',
    LiveTrackingWillStart:'Θα ξεκινήσει η ζωντανή παρακολούθηση',
    DriverTrackerWillGetActivatedOnceTheyAreOnWay:  'Το εργαλείο παρακολούθησης του οδηγού θα ενεργοποιηθεί μόλις ο οδηγός είναι στον δρόμο',
    TrackerTimeline:'Ιστορικό',
    PM:'ΜΜ',
    AM:'ΠΜ',
    TodayAt:'σήμερα στις',
    TomorrowAt:'αύριο στις',
    Today:'Σήμερα',
    Tomorrow:'Αύριο',
    On:'στο',
    In:'σε',
    Soon:'σύντομα',
    Min:'λεπτά',
    Mins:'λεπτά',
    Items:'ΣΤΟΙΧΕΙΑ',
    ViewDetails: 'Ιστορικό παράδοσης ',
    DeliveryInformation:'Πληροφορίες παράδοσης',
    TrackingHistory:'Ιστορικό παρακολούθησης',
    StayConnected:'Παραμείνετε συνδεδεμένοι',
    YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'Έχετε ήδη προσπαθήσει να πραγματοποιήσετε μια πληρωμή τα τελευταία λίγα λεπτά. Θέλετε να ακυρώσετε τις προηγούμενες συναλλαγές;',
    No: 'ΌΧΙ',
    yes:'ΝΑΙ',
    YourPackageWillBeDeliveredBy : 'Η παραγγελία σου θα παραδοθεί από',
    OrderDetails: 'Λεπτομέρειες παραγγελίας',
    OrderInformation:'Πληροφορίες παραγγελίας',
    LastAttempted:'Τελευταία προσπάθεια',
    DeliveryBy:'Παράδοση από',
    Pickuppointisupdated:'Το Pickup Point ενημερώνεται',
    MsgToFePageHeader:'Στείλε μήνυμα στον οδηγό',
    MsgToFeSupportingText:'Τι θέλεις να πείς στον οδηγό;',
    AddYourMsgHere:'Γράψε το μήνυμα σου εδώ',
    InstructionsSentSuccessfully:'Instructions Sent Successfully',
    SomethingWentWrongPlsTryAgain:'Something went wrong, please try again.',
    times:"times",
    TrackMovementInRealTime:"Track Movement in Real-Time",
    Hours:"hours",
    Hour:"hour",
    WriteYourFeedbackOptional:"Γράψτε τα σχόλιά σας (Προαιρετικό)",
    ReturnOrder: "Return Order",
    TheLinkYouAreTryingToOpenHasExpired: "Ο σύνδεσμος που προσπαθείτε να ανοίξετε έχει λήξει    ",
    SelectAnAvailableTimeSlot:"Select an available time slot",
    January:"JANUARY",
    March :"MARCH",
    February:"FEBRUARY",
    May:"MAY",
    June:"JUNE",
    July:"JULY",
    August:"AUGUST",
    September:"SEPTEMBER",
    October:"OCTOBER",
    November:"NOVEMBER",
    December:"DECEMBER",
    Monday:"MONDAY",
    Tuesday:"TUESDAY",
    Wednesday:"WEDNESDAY",
    Thursday:"THURSDAY",
    Friday:"FRIDAY",
    Saturday:"SATURDAY",
    Sunday:"SUNDAY",
    April:"APRIL",
    ViewTimings: "View Timings",
    HideTimings: "Hide Timings",
    PleaseAnswerAllMandatoryQuestions:"Απαντήστε σε όλες τις υποχρεωτικές ερωτήσεις",
    HelpLineLink: 'Helpline Link',
    ShipmentOf: "Αποστολή%από",
    OtherShipments: "Άλλες Αποστολές",
    OrderInformation:"Order Information",
    OR:"ή",
    TrackShipment:"Παρακολούθηση αποστολής",
    TrackingDescription:"Εισαγάγετε τα παρακάτω στοιχεία για να λάβετε λεπτομέρειες σχετικά με την αποστολή σας",
    Continue:"Συνεχίζω",
    InvalidCredentials:"Μη έγκυρα στοιχεία σύνδεσης. Δοκιμάστε ξανά"
    }
    export default greekStrings