import React from "react";
import { isEmpty } from "lodash";

import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import {withStyles} from '@material-ui/core/styles';

import { MyContext } from "../containers/context";
import { processUpdateFormService } from "../services/ProcessUpdateFormService";


class MessageToFE extends React.Component {

    state = {
        message: "",
        preMsgIndex: -1,
        openSendMsgSnack:false,
        disableSendButton:false
    };

    handleRadioChange = (e) => {
        var index = e.target.value;
        if (index < this.props.feMsgDetails.preMessageArr.length) {
            this.setState({
                preMsgIndex: index,
                message: this.props.feMsgDetails.preMessageArr[index],
                disableSendButton:false
            })
        } else {
            this.setState({
                preMsgIndex: index,
                message: "",
                disableSendButton:false
            })
        }

    }
    sendMessage = async () => {
        var messageText = this.state.message;

        if (isEmpty(messageText) || isEmpty(messageText.trim())) {
            return;
        }
        messageText = messageText.trim();
        try {
            this.setState({ disableSendButton: true })
            let res = await processUpdateFormService.sendMessage(
                this.props.sendMsgDetails.url,
                this.props.sendMsgDetails.orderId,
                messageText,
                this.props.sendMsgDetails.userId);

            this.onModalClose();
            this.props.handleSuccessSendMessage();
        } catch (e) {
            this.setState({
                openSendMsgSnack: true,
                disableSendButton: false
            });
        }

    }
    onModalClose = () => {
        this.setState({ message: "", preMsgIndex: -1 , openSendMsgSnack: false})
        this.props.goBack();
    }

    getSendMsgResponseSnack=()=>{
        return (
          <div className="send-msg-snack-bar">
            <Snackbar
              anchorOrigin={this.props.isMobileView ?
                {vertical: 'bottom',horizontal: 'center' }:{vertical: 'top',horizontal: 'right' }}
              open={ this.state.openSendMsgSnack}
              message={containerConstants.SomethingWentWrongPlsTryAgain}
              key="sendMsgErrSnack"
              autoHideDuration={3500}
              onClose={()=>{this.setState({openSendMsgSnack: false})}}
            />
          </div>
      
        );
      }

    getPreMessage = (primaryBgColor) => {
        const StyledRadio = withStyles({
            root: {
              '&$checked': {
                color: primaryBgColor
              }
            },
            checked: {}
          })(Radio);
        return (
            <div className="message-div">
                <FormControl>
                    <RadioGroup onChange={this.handleRadioChange}>

                        {this.props.feMsgDetails.preMessageArr &&
                            this.props.feMsgDetails.preMessageArr.map((preMsg, index) => {
                                return (
                                    <FormControlLabel
                                        className={this.state.preMsgIndex==index?"pre-message selected-msg":"pre-message"}
                                        key={index}
                                        control={<StyledRadio color="primary" size="small" />}
                                        value={"" + index}
                                        label={<span style={{ fontFamily:`${this.props.fontType||"Roboto"},sans-serif`}}>{preMsg}</span>}
                                    />
                                );
                            })
                        }
                        <FormControlLabel
                            className={this.state.preMsgIndex==this.props.feMsgDetails.preMessageArr.length?"pre-message selected-msg":"pre-message"}
                            key="cust"
                            control={<StyledRadio color="primary" size="small" />}
                            label={<span style={{ fontFamily:`${this.props.fontType||"Roboto"},sans-serif`}}>{containerConstants.NotListedAboveSendACustomMessage}</span>}  
                            value={"" + this.props.feMsgDetails.preMessageArr.length}
                        />

                    </RadioGroup>
                </FormControl>
            </div>
        );
    };

    getCustomMessageBox = () => {
        if (
            !this.props.feMsgDetails.preMessageArr ||
            isEmpty(this.props.feMsgDetails.preMessageArr) ||
            (this.state.preMsgIndex >= this.props.feMsgDetails.preMessageArr.length)
        ) {
            return (
                <div className="cust-message-box">
                    <TextField
                        placeholder={containerConstants.AddYourMsgHere}
                        variant="outlined"
                        multiline={true}
                        onChange={(event) => {
                            if (event.target.value.length < 451) {
                                this.setState({ message: event.target.value });
                            }
                        }}
                        value={this.state.message}
                        InputProps={{
                            maxLength: 450,
                            style: {fontFamily:`${this.props.fontType||"Roboto"},sans-serif`,},
                        }}
                    />

                    <p className="char-num" style={{ color: (this.state.message.length < 450) ? "grey" : "red" ,fontFamily:`${this.props.fontType||"Roboto"},sans-serif`}}>
                        {450 - this.state.message.length + " "}
                        {containerConstants.formatString(containerConstants.Characters)}
                    </p>

                </div>
            );
        }
    };
    
    getHeaderForMobileView = (bgColor, textColor) => {
        return (
            <div className="page-header" style={{ backgroundColor: bgColor, color: textColor,fontFamily:`${this.props.fontType||"Roboto"},sans-serif`}}>
                <p><i className="left-arrow ml5 mr5" onClick={() => { this.props.goBack() }} style={{borderColor: textColor}}></i>
                    {isEmpty(this.props.feMsgDetails.pageHeader) ? containerConstants.MsgToFePageHeader : this.props.feMsgDetails.pageHeader}</p>
            </div>
        )
    }
    getFooterForMobileView = (bgColor, textColor,secondaryBgColor) => {
        return (
            <div className="page-footer">

                <Button className="footer-buttons left-btn "
                    style={{ color: bgColor,backgroundColor :secondaryBgColor,fontFamily:`${this.props.fontType||"Roboto"},sans-serif` }}
                    onClick={() => { this.props.goBack() }}>{containerConstants.Cancel}</Button>

                <Button
                    style={(isEmpty(this.state.message) || isEmpty(this.state.message.trim()) || this.state.disableSendButton) ? {fontFamily:`${this.props.fontType||"Roboto"},sans-serif`} : { backgroundColor: bgColor, color: textColor,fontFamily:`${this.props.fontType||"Roboto"},sans-serif` }}
                    className="footer-buttons right-btn"
                    color="primary" variant="contained"
                    id="sendMessageToFeButton"
                    disabled={(isEmpty(this.state.message)|| isEmpty(this.state.message.trim()) || this.state.disableSendButton)}
                    onClick={this.sendMessage}>{containerConstants.Send}</Button>


            </div>
        )
    }
    getBodyForMobileView = (primaryBgColor) => {
        return (
            <div className="page-body">
                <p className="supporting-txt" style={{fontFamily:`${this.props.fontType||"Roboto"},sans-serif`}}>{isEmpty(this.props.feMsgDetails.supportingText) ? containerConstants.MsgToFeSupportingText : this.props.feMsgDetails.supportingText}</p>

                {this.props.feMsgDetails.preMessageArr && !isEmpty(this.props.feMsgDetails.preMessageArr) &&
                    this.getPreMessage(primaryBgColor)
                }
                {this.getCustomMessageBox()}
            </div>
        )

    }

    getHeaderForDesktopModal = (bgColor, textColor) => {
        return (
            <DialogTitle id="max-width-dialog-title" className="page-header" style={{ backgroundColor: bgColor, color: textColor }}>
                <span className="header-txt" style={{ color: textColor,fontFamily:`${this.props.fontType||"Roboto"},sans-serif` }}>{isEmpty(this.props.feMsgDetails.pageHeader) ? containerConstants.MsgToFePageHeader : this.props.feMsgDetails.pageHeader}</span>
                <span className="close-icon"><IconButton onClick={this.onModalClose} style={{ color: textColor }}><CloseIcon style={{fill:textColor}}/></IconButton></span>
            </DialogTitle>
        )

    }

    getFooterForDestopModal = (bgColor, textColor,secondaryBgColor) => {
        return (
            <DialogActions className="page-footer">
                <Button className="footer-buttons left-btn"
                    color="primary"
                    style={{ color: bgColor ,backgroundColor :secondaryBgColor,fontFamily:`${this.props.fontType||"Roboto"},sans-serif`}}
                    onClick={this.onModalClose}>{containerConstants.Cancel}</Button>

                <Button
                    style={(isEmpty(this.state.message) || isEmpty(this.state.message.trim()) || this.state.disableSendButton) ? {fontFamily:`${this.props.fontType||"Roboto"},sans-serif`} : { backgroundColor: bgColor, color: textColor,fontFamily:`${this.props.fontType||"Roboto"},sans-serif`}}
                    className="footer-buttons right-btn"
                    color="primary" variant="contained"
                    disabled={(isEmpty(this.state.message) || isEmpty(this.state.message.trim()) || this.state.disableSendButton)}
                    onClick={this.sendMessage}>{containerConstants.Send}</Button>
            </DialogActions>
        )

    }
    getBodyForDesktopModal = (primaryBgColor) => {
        return (
            <DialogContent id="fe-msg-dialog-description">
                {this.getBodyForMobileView(primaryBgColor)}
            </DialogContent>
        )
    }



    getFeToMsgView = () => {
        return (
            <MyContext.Consumer>
                {({ primaryBgColor, primaryTextColor,secondaryBgColor }) => {

                    if (this.props.isMobileView) {
                        return (

                            <div className="msg-to-fe-mobile">
                                {this.getHeaderForMobileView(primaryBgColor, primaryTextColor)}
                                {this.getBodyForMobileView(primaryBgColor)}
                                {this.getFooterForMobileView(primaryBgColor, primaryTextColor,secondaryBgColor)}
                            </div>

                        )
                    }
                    else {
                        return (

                            <Dialog
                                className="msg-to-fe-desktop"
                                fullWidth={true}
                                maxWidth={"sm"}
                                open={this.props.openModal}
                                onClose={(e,reason)=>{
                                    if(reason!='backdropClick'){
                                        this.onModalClose();
                                    }
                                }}
                                aria-labelledby="fe-msg-dialog-title"
                                aria-describedby="fe-msg-dialog-description"
                            >
                                {this.getHeaderForDesktopModal(primaryBgColor, primaryTextColor)}
                                {this.getBodyForDesktopModal(primaryBgColor)}
                                {this.getFooterForDestopModal(primaryBgColor, primaryTextColor,secondaryBgColor)}
                            </Dialog>


                        )
                    }
                }}
            </MyContext.Consumer>
        )
    }


    render() {

        return (
            <div className="msg-to-fe">
                {this.getFeToMsgView()}
                {this.getSendMsgResponseSnack()}
            </div>
        );

    }

}

export default MessageToFE;

/*
props:{
    feMsgDetails:{
        pageHeader: feMsgPageHeader,
        supportingText: feMsgSupportingText,
        preMessageArr: preMessage
    }
    sendMsgDetails:{
        url:"",
        orderId:"",
        userId:""
    }
    isMobileView:true
    handleSuccessSendMessage:callback
    openModal:false
    goBack:callback
}
*/