const arabicStrings = {
YourShipmentHasBeenDelivered:'تم تسليم شحنتك',
DeliveredBy:'تسليم بواسطة',
RateYourExperience:'تقييم تجربتك',
ThankYouForYourValuableFeedback:'شكرًا لك على ملاحظاتك القيّمة',
YourFeedbackWillContinuallyHelpUsImproveOurServices:'تساعدنا ملاحظاتك باستمرار على تحسين خدماتنا',
DeliveryFailed:'فشل التسليم',
AddDeliveryPreferences:'إضافة تفضيلات التسليم',
YourDeliveryPreferenceAreSavedSuccessFully:'تم حفظ تفضيلات تسليمك بنجاح',
WeAreUnableToTrackYourShipmentRightNow:'يتعذر علينا تتبع شحنتك حاليًا',
PleaseUpdateLocationInsideCircle:'يُرجى تحديث الموقع داخل الدائرة',
Ok:'موافق',
Update:'تحديث',
PickCurrentLocation:'تحديد الموقع الحالي',
SearchFor:'بحث عن',
ThisSchedulingLinkHasExpired:'انتهت صلاحية رابط الجدولة هذا',
WeWillShareANewLinkWithYouShortly:'سنشارك رابطًا جديدًا معك قريبًا',
UhHo:'عذرًا!',
NeedHelp:'هل تحتاج إلى المساعدة؟',
CallSupport:'طلب الدعم',
EmailSupport:'إرسال رسالة إلى الدعم',
DeliveryAt:'تاريخ التسليم',
DeliveredAt: 'Delivered At',
ContactNo:'اتصال بالرقم',
TrackOnMap:'تتبع على الخريطة',
MessageToExecutive:'إرسال رسالة إلى المدير التنفيذي',
Cancel:'إلغاء',
Send:'إرسال',
Executive:'مدير تنفيذي',
Comments:'تعليقات (اختيارية)',
SubmitFeedback:'تقديم الملاحظات',
TrackingNo:'تتبع الرقم',
Details:'تفاصيل',
Characters:'أحرف',
Updating:'تحديث....',
OopsSomethingWentWrongPleaseTryAfterSomeTime:'عذرًا! حدث خطأ ما، يُرجى المحاولة بعد قليل',
NotListedAboveSendACustomMessage:'غير معروض أعلاه، أرسل رسالة مخصصة',
ChangeDeliveryLocation:'تغيير موقع التوصيل',
AttemptedBy:'تمت المحاولة بواسطة',
DeliveredTo:'تم التوصيل إلى',
SendMessage:'إرسال رسالة',
VIEW_DETAILS:'تفاصيل الطلب',
SAVE_LOCATION:'حفظ الموقع',
LOCATION:'الموقع',
SEARCH_ADDRESS:'عنوان البحث',
OpenHours:'کُھلنے کے اوقات',
Note:'نوٹ',
Address:'پتہ',
Nearest: 'قریب ترین',
ContactNumber:'رقم التواصل',
FAQS:'الأسئلة الشائعة',
HowManyTimesCanIChangeMyPickUpPoint: 'كم عدد المرات التي يمكنني خلالها تغيير نقطة الاستلام الخاصة بي؟',
YouCanChangeItOnly: 'يمكنك تغييره فقط',
GoBack:'العودة',
SetAsPickupStore:'تحديد كمتجر استلام',
MapView:'عرض الخريطة',
SearchForPickupPointNearYou:'البحث عن نقطة استلام بالقرب منك',
Arriving:'الوصول',
LiveTrackingWillStart:'سيبدأ التتبع المباشر',
DriverTrackerWillGetActivatedOnceTheyAreOnWay:'سيتم تنشيط مُتتبع السائق فور أن يصبح في الطريق',
TrackerTimeline:'تتبع الطلب',
PM:'م',
AM:'ص',
TodayAt:'اليوم في الساعة',
TomorrowAt:'غدًا الساعة',
Today:'اليوم',
Tomorrow:'غدًا',
On:'في يوم',
In:'في',
Soon:'قريبًا',
Min:'دقيقة',
Mins:'دقائق',
Items:'اشیاء',
ViewDetails: 'تفاصيل الطلب',
DeliveryInformation:' بيانات الضيف',
TrackingHistory:'تاريخ التتبع',
StayConnected:'جڑے رہیں',
YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'آپ نے پہلے ہی گزشتہ چند منٹوں میں ادائیگی کرنے کی کوشش کی ہے۔ کیا آپ سابقہ ٹرانزیکشنوں کو منسوخ کرنا چاہتے ہیں',
No: 'نہیں',
yes:'ہاں',
YourPackageWillBeDeliveredBy : 'سوف يتم توصيل طلبك عن طريق الكابتن',
OrderDetails: 'تفاصيل الطلب',
OrderInformation:'آرڈر کی معلومات',
LastAttempted:'آخر محاولة',
DeliveryBy:'قام بالتوصيل',
Pickuppointisupdated:'تم تحديث نقطة الالتقاط',
MsgToFePageHeader:'إرسال تعليمات التوصيل',
MsgToFeSupportingText:'ما الذي تريد إبلاغ السائق به؟',
AddYourMsgHere:'أضِف رسالتك هنا',
InstructionsSentSuccessfully:'تم إرسال التعليمات بنجاح',
SomethingWentWrongPlsTryAgain:'حدث خطأ، الرجاء إعادة المحاولة.',
times:"مرة/مرات",
TrackMovementInRealTime:"حركة التتبع في الوقت الفعلي",
Hours:"hours",
Hour:"hour",
WriteYourFeedbackOptional:"اكتب ملاحظاتك (اختياري)",
ReturnOrder: "Return Order",
TheLinkYouAreTryingToOpenHasExpired: "لقد انتهت صلاحية الرابط الذي تحاول فتحه",
SelectAnAvailableTimeSlot:"Select an available time slot",
January:"JANUARY",
February:"FEBRUARY",
March :"MARCH",
April:"APRIL",
May:"MAY",
June:"JUNE",
July:"JULY",
August:"AUGUST",
September:"SEPTEMBER",
October:"OCTOBER",
November:"NOVEMBER",
December:"DECEMBER",
Monday:"MONDAY",
Tuesday:"TUESDAY",
Wednesday:"WEDNESDAY",
Thursday:"THURSDAY",
Friday:"FRIDAY",
Saturday:"SATURDAY",
Sunday:"SUNDAY",
ViewTimings: "View Timings",
HideTimings: "Hide Timings",
PleaseAnswerAllMandatoryQuestions:"الرجاء الإجابة على جميع الأسئلة الإلزامية",
HelpLineLink: 'Helpline Link',
ShipmentOf:"الشحنة%من",
OtherShipments:"شحنات أخرى",
OrderInformation:"Order Information",
OR:"أو",
TrackShipment:"تتبع الشحن",
TrackingDescription:"أدخل التفاصيل أدناه للحصول على تفاصيل شحنتك",
Continue:"يكمل",
InvalidCredentials:"بيانات الاعتماد غير صحيحة. يرجى المحاولة مرة أخرى."


}
export default arabicStrings;
