import { isEmpty } from "lodash";
import React from "react";
import { mediaLinkService } from "../../services/MediaLinkService";
import { Button } from "antd";
import { RightArrow } from "../../containers/ProcessUpdateForm/Icons";
import { OpenShipmentIcon, ShipmentIcon, ShipmentIconV2 } from "../HelpSection/icons";

function getImages(shipmentInfo) {
  if (!shipmentInfo) {
    return;
  }
  // let imageList = ["https://picsum.photos/200/300", "https://picsum.photos/200/300", "https://picsum.photos/200/300"];
  let images = shipmentInfo.map((shipment,i) => {
    if(shipment.itemImage)
    return <span><img className="imageOfShipment" src={shipment.itemImage} key={i}/></span>
  });
  return images;
}

function getETAString(etaFromServer) {
  if (
    etaFromServer &&
    (etaFromServer.indexOf("today") != -1 ||
      etaFromServer.indexOf("tomorrow") != -1)
  ) {
    let arr = etaFromServer.split(" ");
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] && !isEmpty(arr[i])) {
        if (arr[i].indexOf("today") != -1 || arr[i].indexOf("tomorrow") != -1) {
          if (
            arr[i + 1] &&
            !isEmpty(arr[i + 1]) &&
            arr[i + 1].indexOf("at") != -1
          ) {
            arr[i] =
              arr[i].indexOf("today") != -1
                ? containerConstants.formatString(containerConstants.TodayAt)
                : containerConstants.formatString(
                  containerConstants.TomorrowAt
                );
            arr[i + 1] = "";
          } else {
            arr[i] =
              arr[i].indexOf("today") != -1
                ? containerConstants.formatString(containerConstants.Today)
                : containerConstants.formatString(containerConstants.Tomorrow);
          }
        } else if (arr[i].indexOf("PM") != -1 || arr[i].indexOf("AM") != -1) {
          arr[i] =
            arr[i].indexOf("PM") != -1
              ? containerConstants.formatString(containerConstants.PM)
              : containerConstants.formatString(containerConstants.AM);
        }
      }
    }
    etaFromServer = "";
    for (let i = 0; i < arr.length; i++) {
      etaFromServer = etaFromServer + arr[i] + " ";
    }
  }
  return " " + (etaFromServer || "");
}

function getEta(props) {
  return isEmpty(props.orderData?.etaHeading)
    ? "Estimated arrival on monday"
    : props.orderData?.etaHeading;
}
function ShipmentList(props) {
  let orderAttributeList = props.orderData?.shipments;
  let totalShipmentCount = orderAttributeList?.length;
  let SHIPMENTOF=props.containerConstants?.formatString(props.containerConstants.ShipmentOf)?.split("%") || ['Shipment','of'];
  return (
    <ul 
     style={{
      background:props?.card?.cardBgcolour||'white',
      borderRadius:props?.card?.cornerRedius?`0px 0px ${props?.card?.cornerRedius||0}px ${props?.card?.cornerRedius||0}px`:'',
      borderRight:props?.border?props?.border[1]:'',
      borderBottom:props?.border?props?.border[2]:'',
      borderLeft:props?.border?props?.border[3]:'',
      }}>
      {orderAttributeList &&
        orderAttributeList.map((shipment, index) => {
          if (
            !props?.referenceNumber ||
            shipment?.reference_number != props?.referenceNumber
          ) {
            return (
              // <li style={{ margin: "10px 8px" }}>
              //   <div className="shipment bg-white">
              //     <div className="shipment-navbar flex " style={{ marginBottom: "12px" }}>
              //       <div>
              //         <div className="flex align-center ">
              //           <ShipmentIcon />
              //           <p className="ml5 fw400" style={{ color: "#647788" }}>{"  Shipment"} {index + 1} of {totalShipmentCount}</p>
              //         </div>
              //         <p style={{ lineHeight: "20px" }} className="fw400">{isEmpty(props.orderData?.etaHeading)?" ":props.orderData?.etaHeading+" "}{getETAString(shipment.eta)}</p>
              //       </div>

              //       <div className="shipmentOpen">
              //         <div className="mt5" onClick={() => { props.getShipmentData(shipment.trackUrl) }}>
              //           <OpenShipmentIcon
              //             color={props.theme?.primaryBgColor ? props.theme?.primaryBgColor : "red"} />
              //         </div>
              //       </div>
              //     </div>
              //     <div className="border-bottom-2"></div>
              //     <div className="shipment-info " style={{ display: "flex" }}>
              //       {shipment.shipmentInfo?
              //       <div>
              //       {shipment.shipmentInfo && shipment.shipmentInfo.length > 0 && <p style={{ marginTop: "19px" }} className="mb10 fw400">{shipment.shipmentInfo?.length} {shipment.shipmentInfo.length > 1 ? "item(s)" : "item"}</p>}

              //       <div style={{ display: "flex" }}>{getImages(shipment.shipmentInfo)}</div>
              //     </div>:null}

              //       <div style={{ marginLeft: "auto" }}><p className="shipmentStatus"> {shipment.status}</p></div>
              //     </div>
              //     </div>
              //   </li>

              <li>
                <div className="shipment2">
                  <div
                    className="flex justify-between align-start"
                    style={{ marginRight:"24px"}}
                  >
                        <div className="flex align-start" style={{width:'150px'}} >
                        <ShipmentIconV2 />
                        <p className="ml8 fw500 shipment-count">
                          {`${SHIPMENTOF[0]} ${index+1} ${SHIPMENTOF[1]} ${totalShipmentCount}`}
                        </p>

                        </div>
                      <div className="flex align-start " style={{gap:'10px'}}>
                        <p className="shipmentStatus2" > {shipment.status}</p>
                        <div className="shipmentOpen"
                            onClick={() => {
                              props.getShipmentData(shipment.trackUrl);
                            }}
                          >
                            <OpenShipmentIcon
                              color={
                                props.theme?.primaryBgColor
                                  ? props.theme?.primaryBgColor
                                  : "red"
                              }
                            />
                      </div>
                    </div>
                  </div>
                  {/* <div className="border-bottom-2"></div> */}
                  {/* commenting the below block of code as of now ,(MK) eta should not be visible on Shipment list  
                  {
                    (props.orderData?.etaHeading || shipment.eta) && 
                    <>
                    <div style={{borderBottom:'1px solid #F3F3F3',margin:'9px 24px 0px 0px'}}></div>

                      <p 
                      className="mt8 eat-info">
                        {
                          isEmpty(props.orderData?.etaHeading)
                          ? " "
                          : props.orderData?.etaHeading + " "}
                        {getETAString(shipment.eta)}
                      </p>
                          
                          </>
                        } */}
                    {
                    shipment.shipmentInfo?.length>0 &&
                  <div className="shipment-info flex" >
                       
                            <p
                            
                            style={{
                              fontSize:'14px', 
                              width:"75px",
                              color:"#647788",
                              lineHeight:'20px'
                            }}
                            className="shipment-p-info"
                            >
                              {shipment.shipmentInfo?.length}{" "}
                              {shipment.shipmentInfo.length > 1
                                ? "item(s)"
                                : "item"}
                            </p>

                        <div className="shipment-images">
                          {getImages(shipment.shipmentInfo)}
                        </div>
                         
                    </div>
                    }
                    {/* <div style={{ marginLeft: "auto" }}>
                    </div> */}
                </div>
              </li>
            );
          }
        })}
    </ul>
  );
}

export default ShipmentList;
